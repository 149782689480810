import React from 'react';
// import Snap from 'snapsvg-cjs';
import "./svg-garden.scss";


//insert Element.prototype.limitDrag function here
class SvgGarden extends React.Component{
  componentDidMount() {
//
// var s = Snap("#svg");

//handles snail blinking
//   function blinksnail() {
//       lidsnail.animate({
//           d: start
//       }, 200, function () {
//           lidsnail.animate({
//               d: end
//           }, 200, function () {})
//       })
//   }

  //handles the rest of the creatures blinking
//   function blinkf() {
//       lidf.animate({
//           d: "m 1023.3165,3774.8799 c 0,0.5842 -12.2529,8.724 -27.36754,8.724 -15.11466,0 -27.21452,-8.1531 -27.3675,-8.724 -1.23883,-4.6234 12.25284,-8.6667 27.3675,-8.6667 15.11464,0 27.36754,3.8802 27.36754,8.6667 z"
//       }, 200, function () {
//           lidf.animate({
//               d: "m 1023.3165,3774.8799 c 0,0.5842 -12.2529,-6.8903 -27.36754,-6.8903 -15.11466,0 -27.21452,7.4612 -27.3675,6.8903 -1.23883,-4.6234 12.25284,-8.6667 27.3675,-8.6667 15.11464,0 27.36754,3.8802 27.36754,8.6667 z"
//           }, 300, function () {})
//       })
//   }
  

//   var lidsnail = s.select("#leftEyeLidsnail"),
    //   start = "m -3905.5722,10856.694 c 0,5.307 -63.0486,82.243 -140.823,82.243 -77.7744,0 -140.0358,-77.057 -140.823,-82.243 -6.3745,-41.994 63.0486,-78.719 140.823,-78.719 77.7744,0 140.823,35.244 140.823,78.719 z",
    //   end = "m -3905.5722,10856.694 c 0,5.307 -63.0486,-62.584 -140.823,-62.584 -77.7744,0 -140.0358,67.77 -140.823,62.584 -6.3745,-41.994 63.0486,-78.719 140.823,-78.719 77.7744,0 140.823,35.244 140.823,78.719 z",
    //  var lidf = s.select("#leftEyeLidSun"),
    //   id1flower = setInterval(blinkf, Math.round(1e4 * Math.random() + 2e3))
     }
render () {

    return (
      <svg id="svg" viewBox="0 0 1194.62 768.89769"  
>



      <defs id="defs13428">
          <linearGradient id="linearGradient5118" y2="580.93" gradientUnits="userSpaceOnUse" x2="28.571" gradientTransform="matrix(.90832 0 0 .59996 515.14 427.66)" y1="9.505" x1="20">
              <stop id="stop5615" stop-color="#2c873b" offset="0" />
              <stop id="stop5617" stop-color="#2cca1d" offset="1" />
          </linearGradient>
          <linearGradient id="linearGradient5123" y2="1711" gradientUnits="userSpaceOnUse" x2="-606.09" gradientTransform="matrix(1.0085 0 0 1.0085 1247.5 -1170.8)" y1="1189.7" x1="-610.13">
              <stop id="stop5529" stop-color="#006acb" offset="0" />
              <stop id="stop5531" stop-color="#fff" offset="1" />
          </linearGradient>
      </defs>

      <path id="path20236" d="m1194.7 612.77c-24.513 0.1042-1195-12.786-1195-12.786 0.009-11.409 0.21312-599.99 0.21312-599.99s1181.8 0.0549 1194.8-0.0245c-0.038 9.0686-1.4339 413.57-0.1002 612.8z" fill="url(#linearGradient5123)" />
      <g id="cloudsB">
          <path id="path20240-7" d="m866.82 383.75c-10.596 0-19.391 5.0527-21.27 11.73-7.1895 1.8118-12.453 7.6525-12.453 14.582 0 5.317 3.1023 9.9862 7.793 12.694-0.0339 0.40324-0.0602 0.81348-0.0602 1.2251 0 8.2046 6.8638 14.863 15.325 14.863 3.6596 0 7.006-1.258 9.6408-3.3341 2.8956 1.135 6.1947 1.7876 9.701 1.7876 5.222 0 9.992-1.4222 13.638-3.776 0.93194 0.23523 1.8987 0.36153 2.9123 0.36153 6.068 0 10.986-4.5073 10.986-10.063 0-2.6309-1.1062-5.0163-2.9123-6.8088 2.7689-2.7108 4.4589-6.3588 4.4589-10.364 0-8.3755-7.4053-15.164-16.55-15.164-0.64614 0-1.2812 0.0347-1.9081 0.10046-3.5904-4.6509-10.892-7.8331-19.302-7.8331z"
              fill-opacity=".60638" fill="#fff" />
          <g id="g6283-8" transform="matrix(.64272 0 0 .64272 485.3 50.488)">
              <path id="path20618-3" d="m582.38 520.48c-16.472 0-30.153 7.8446-33.094 18.219-11.188 2.8178-19.375 11.905-19.375 22.688 0 8.2756 4.8226 15.569 12.125 19.781-0.0527 0.62735-0.0937 1.2657-0.0937 1.9062 0 12.766 10.679 23.094 23.844 23.094 5.7029 0 10.929-1.9169 15.031-5.1562 4.4967 1.7574 9.6214 2.75 15.062 2.75 8.1187 0 15.517-2.2177 21.188-5.875 1.4535 0.36793 2.9811 0.59375 4.5625 0.59375 9.4411 0 17.094-7.013 17.094-15.656 0-4.0934-1.7211-7.8049-4.5312-10.594 4.3082-4.2177 6.9375-9.8936 6.9375-16.125 0-13.031-11.522-23.594-25.75-23.594-1.0053 0-1.9934 0.0536-2.9688 0.15625-5.5863-7.2363-16.948-12.188-30.031-12.188z"
                  fill="#fff" />
              <g id="g6061-1" transform="translate(-3.9286 -.71429)">
                  <path id="path13367-6" d="m557.13 575.16c5.1228 10.326 13.434 13.879 25.538 9.3762" stroke-opacity=".21939" stroke="#000" stroke-width=".48518px" fill="none" />
                  <path id="path13369-9" d="m612.08 542.08c10.29 5.1933 13.787 13.529 9.2014 25.602" stroke-opacity=".21939" stroke="#000" stroke-width=".48518px" fill="none" />
                  <path id="path13371-1" d="m551.17 561.31c0.98253-11.485 7.4132-17.837 20.326-18.083" stroke-opacity=".21939" stroke="#000" stroke-width=".48518px" fill="none" />
                  <path id="path13373-82" d="m578.63 578.66c5.1228 10.326 13.434 13.879 25.538 9.3763" stroke-opacity=".21939" stroke="#000" stroke-width=".48518px" fill="none" />
                  <path id="path13375-1" d="m615.85 565.47c10.175 5.4152 13.49 13.824 8.644 25.795" stroke-opacity=".21939" stroke="#000" stroke-width=".48518px" fill="none" />
              </g>
          </g>
          <path id="path4291-6" opacity=".303" d="m967.48 616.47c-5.3754 0-9.8368 3.3897-10.79 7.8691-3.6472 1.2155-6.3171 5.1339-6.3171 9.7825 0 3.5671 1.5738 6.6995 3.9533 8.5159-0.0172 0.27052-0.0305 0.54575-0.0305 0.82193 0 5.5043 3.4819 9.9711 7.7741 9.9711 1.8564 0 3.554-0.84396 4.8906-2.2368 1.4689 0.76147 3.1425 1.1993 4.9212 1.1993 2.649 0 5.0688-0.95413 6.9182-2.5332 0.47276 0.15782 0.96317 0.24255 1.4774 0.24255 3.0782 0 5.5733-3.0239 5.5733-6.7508 0-1.765-0.56114-3.3654-1.4774-4.5678 1.4046-1.8186 2.2619-4.266 2.2619-6.9528 0-5.6189-3.7566-10.173-8.3956-10.173-0.32778 0-0.64995 0.0232-0.96795 0.0674-1.8214-3.1202-5.5256-5.2551-9.7915-5.2551z"
              fill-opacity=".60638" fill="#fff" />
          <use id="use5149-1" opacity="0.292" xlinkHref="#path4291-6" transform="matrix(.66572 0 0 .66572 -23.827 338.93)" height="100%" width="100%" y="0" x="0" />
          <use id="use5151-0" opacity="0.643" xlinkHref="#g6283-8" transform="matrix(.58220 0 0 .58220 707.58 306.62)" height="100%" width="100%" y="0" x="0" />
          <use id="use5153-2" opacity="0.438" xlinkHref="#g6283-8" transform="matrix(.53366 0 0 .53366 615.34 375.52)" height="100%" width="100%" y="0" x="0" />
          <use id="use5155-9" opacity=".225" xlinkHref="#path4291-6" transform="translate(386.53 161.24)" height="100%" width="100%" y="0" x="0" />
          <use id="use5157-5" opacity=".225" xlinkHref="#path20240-7" transform="matrix(.41327 0 0 .41327 375.06 641.52)" height="100%" width="100%" y="0" x="0" />
          <use id="use5159-1" opacity=".225" xlinkHref="#path20240-7" transform="matrix(.44994 0 0 .44994 1062.5 583.79)" height="100%" width="100%" y="0" x="0" />
          <use id="use5161-9" xlinkHref="#g6283-8" transform="translate(-321.33 67.608)" height="100%" width="100%" y="0" x="0" />
          <use id="use5908-4" xlinkHref="#g6283-8" transform="matrix(.86011 0 0 .86011 636.97 88.146)" height="100%" width="100%" y="0" x="0" />
          <use id="use5163-9" xlinkHref="#g6283-8" transform="translate(553.92 4.5308)" height="100%" width="100%" y="0" x="0" />
          <use id="use5165-5" opacity="0.365" xlinkHref="#path20240-7" transform="matrix(.39493 0 0 .39493 370.7 483.13)" height="100%" width="100%" y="0" x="0" />
          <use id="use5167-4" opacity="0.365" xlinkHref="#path20240-7" transform="matrix(.37659 0 0 .37659 399.1 481.07)" height="100%" width="100%" y="0" x="0" />
          <use id="use5169-7" opacity="0.365" xlinkHref="#path20240-7" transform="matrix(.46827 0 0 .46827 369.2 452.96)" height="100%" width="100%" y="0" x="0" />
          <use id="use5171-3" opacity="0.365" xlinkHref="#path20240-7" transform="matrix(.41327 0 0 .41327 437.24 486.19)" height="100%" width="100%" y="0" x="0" />
          <use id="use5898-4" opacity="0.267" xlinkHref="#path4291-6" transform="translate(125.71 155.71)" height="100%" width="100%" y="0" x="0" />
          <use id="use5900-8" opacity="0.267" xlinkHref="#path4291-6" transform="translate(112.86 160)" height="100%" width="100%" y="0" x="0" />
          <use id="use5902-3" opacity="0.267" xlinkHref="#path4291-6" transform="translate(170 174.29)" height="100%" width="100%" y="0" x="0" />
          <use id="use5904-8" opacity="0.267" xlinkHref="#path4291-6" transform="translate(205.71 110)" height="100%" width="100%" y="0" x="0" />
          <use id="use5906-3" xlinkHref="#g6283-8" transform="matrix(1.2598 0 0 1.2598 42.574 -105.74)" height="100%" width="100%" y="0" x="0" />
          <use id="use5910-0" xlinkHref="#g6283-8" transform="translate(-57.579 8.0812)" height="100%" width="100%" y="0" x="0" />
          <use id="use5912-3" xlinkHref="#g6283-8" transform="translate(313.15 -28.284)" height="100%" width="100%" y="0" x="0" />
          <use id="use5914-2" xlinkHref="#g6283-8" transform="translate(-294.17 43.362)" height="100%" width="100%" y="0" x="0" />
          <use id="use5916-8" xlinkHref="#g6283-8" transform="translate(-263.14 81.822)" height="100%" width="100%" y="0" x="0" />
          <use id="use5918-7" xlinkHref="#g6283-8" transform="translate(-234.86 35.355)" height="100%" width="100%" y="0" x="0" />
          <use id="use5920-6" xlinkHref="#g6283-8" transform="translate(-196.47 2.5254)" height="100%" width="100%" y="0" x="0" />
      </g>
      <g id="cloudsA">
          <path id="path20240" d="m866.82 383.75c-10.596 0-19.391 5.0527-21.27 11.73-7.1895 1.8118-12.453 7.6525-12.453 14.582 0 5.317 3.1023 9.9862 7.793 12.694-0.0339 0.40324-0.0602 0.81348-0.0602 1.2251 0 8.2046 6.8638 14.863 15.325 14.863 3.6596 0 7.006-1.258 9.6408-3.3341 2.8956 1.135 6.1947 1.7876 9.701 1.7876 5.222 0 9.992-1.4222 13.638-3.776 0.93194 0.23523 1.8987 0.36153 2.9123 0.36153 6.068 0 10.986-4.5073 10.986-10.063 0-2.6309-1.1062-5.0163-2.9123-6.8088 2.7689-2.7108 4.4589-6.3588 4.4589-10.364 0-8.3755-7.4053-15.164-16.55-15.164-0.64614 0-1.2812 0.0347-1.9081 0.10046-3.5904-4.6509-10.892-7.8331-19.302-7.8331z"
              fill-opacity=".60638" fill="#fff" />
          <g id="g6283-6" transform="matrix(.64272 0 0 .64272 485.3 50.488)">
              <path id="path20618-6" d="m582.38 520.48c-16.472 0-30.153 7.8446-33.094 18.219-11.188 2.8178-19.375 11.905-19.375 22.688 0 8.2756 4.8226 15.569 12.125 19.781-0.0527 0.62735-0.0937 1.2657-0.0937 1.9062 0 12.766 10.679 23.094 23.844 23.094 5.7029 0 10.929-1.9169 15.031-5.1562 4.4967 1.7574 9.6214 2.75 15.062 2.75 8.1187 0 15.517-2.2177 21.188-5.875 1.4535 0.36793 2.9811 0.59375 4.5625 0.59375 9.4411 0 17.094-7.013 17.094-15.656 0-4.0934-1.7211-7.8049-4.5312-10.594 4.3082-4.2177 6.9375-9.8936 6.9375-16.125 0-13.031-11.522-23.594-25.75-23.594-1.0053 0-1.9934 0.0536-2.9688 0.15625-5.5863-7.2363-16.948-12.188-30.031-12.188z"
                  fill="#fff" />
              <g id="g6061-7" transform="translate(-3.9286 -.71429)">
                  <path id="path13367-8" d="m557.13 575.16c5.1228 10.326 13.434 13.879 25.538 9.3762" stroke-opacity=".21939" stroke="#000" stroke-width=".48518px" fill="none" />
                  <path id="path13369-0" d="m612.08 542.08c10.29 5.1933 13.787 13.529 9.2014 25.602" stroke-opacity=".21939" stroke="#000" stroke-width=".48518px" fill="none" />
                  <path id="path13371-2" d="m551.17 561.31c0.98253-11.485 7.4132-17.837 20.326-18.083" stroke-opacity=".21939" stroke="#000" stroke-width=".48518px" fill="none" />
                  <path id="path13373-8" d="m578.63 578.66c5.1228 10.326 13.434 13.879 25.538 9.3763" stroke-opacity=".21939" stroke="#000" stroke-width=".48518px" fill="none" />
                  <path id="path13375-8" d="m615.85 565.47c10.175 5.4152 13.49 13.824 8.644 25.795" stroke-opacity=".21939" stroke="#000" stroke-width=".48518px" fill="none" />
              </g>
          </g>
          <path id="path4291" opacity=".303" d="m967.48 616.47c-5.3754 0-9.8368 3.3897-10.79 7.8691-3.6472 1.2155-6.3171 5.1339-6.3171 9.7825 0 3.5671 1.5738 6.6995 3.9533 8.5159-0.0172 0.27052-0.0305 0.54575-0.0305 0.82193 0 5.5043 3.4819 9.9711 7.7741 9.9711 1.8564 0 3.554-0.84396 4.8906-2.2368 1.4689 0.76147 3.1425 1.1993 4.9212 1.1993 2.649 0 5.0688-0.95413 6.9182-2.5332 0.47276 0.15782 0.96317 0.24255 1.4774 0.24255 3.0782 0 5.5733-3.0239 5.5733-6.7508 0-1.765-0.56114-3.3654-1.4774-4.5678 1.4046-1.8186 2.2619-4.266 2.2619-6.9528 0-5.6189-3.7566-10.173-8.3956-10.173-0.32778 0-0.64995 0.0232-0.96795 0.0674-1.8214-3.1202-5.5256-5.2551-9.7915-5.2551z"
              fill-opacity=".60638" fill="#fff" />
          <use id="use5149" opacity="0.292" xlinkHref="#path4291" transform="matrix(.66572 0 0 .66572 -23.827 338.93)" height="100%" width="100%" y="0" x="0" />
          <use id="use5151" opacity="0.643" xlinkHref="#g6283-6" transform="matrix(.58220 0 0 .58220 707.58 306.62)" height="100%" width="100%" y="0" x="0" />
          <use id="use5153" opacity="0.438" xlinkHref="#g6283-6" transform="matrix(.53366 0 0 .53366 615.34 375.52)" height="100%" width="100%" y="0" x="0" />
          <use id="use5155" opacity=".225" xlinkHref="#path4291" transform="translate(386.53 161.24)" height="100%" width="100%" y="0" x="0" />
          <use id="use5157" opacity=".225" xlinkHref="#path20240" transform="matrix(.41327 0 0 .41327 375.06 641.52)" height="100%" width="100%" y="0" x="0" />
          <use id="use5159" opacity=".225" xlinkHref="#path20240" transform="matrix(.44994 0 0 .44994 1062.5 583.79)" height="100%" width="100%" y="0" x="0" />
          <use id="use5161" xlinkHref="#g6283-6" transform="translate(-321.33 67.608)" height="100%" width="100%" y="0" x="0" />
          <use id="use5908" xlinkHref="#g6283-6" transform="matrix(.86011 0 0 .86011 636.97 88.146)" height="100%" width="100%" y="0" x="0" />
          <use id="use5163" xlinkHref="#g6283-6" transform="translate(553.92 4.5308)" height="100%" width="100%" y="0" x="0" />
          <use id="use5165" opacity="0.365" xlinkHref="#path20240" transform="matrix(.39493 0 0 .39493 370.7 483.13)" height="100%" width="100%" y="0" x="0" />
          <use id="use5167" opacity="0.365" xlinkHref="#path20240" transform="matrix(.37659 0 0 .37659 399.1 481.07)" height="100%" width="100%" y="0" x="0" />
          <use id="use5169" opacity="0.365" xlinkHref="#path20240" transform="matrix(.46827 0 0 .46827 369.2 452.96)" height="100%" width="100%" y="0" x="0" />
          <use id="use5171" opacity="0.365" xlinkHref="#path20240" transform="matrix(.41327 0 0 .41327 437.24 486.19)" height="100%" width="100%" y="0" x="0" />
          <use id="use5898" opacity="0.267" xlinkHref="#path4291" transform="translate(125.71 155.71)" height="100%" width="100%" y="0" x="0" />
          <use id="use5900" opacity="0.267" xlinkHref="#path4291" transform="translate(212.86 187.27)" height="100%" width="100%" y="0" x="0" />
          <use id="use5902" opacity="0.267" xlinkHref="#path4291" transform="translate(170 174.29)" height="100%" width="100%" y="0" x="0" />
          <use id="use5904" opacity="0.267" xlinkHref="#path4291" transform="translate(205.71 110)" height="100%" width="100%" y="0" x="0" />
          <use id="use5906" xlinkHref="#g6283-6" transform="matrix(1.2598 0 0 1.2598 -36.218 -49.173)" height="100%" width="100%" y="0" x="0" />
          <use id="use5910" xlinkHref="#g6283-6" transform="translate(11.112 36.365)" height="100%" width="100%" y="0" x="0" />
          <use id="use5912" xlinkHref="#g6283-6" transform="translate(143.44 85.863)" height="100%" width="100%" y="0" x="0" />
          <use id="use5914" xlinkHref="#g6283-6" transform="translate(598.81 18.108)" height="100%" width="100%" y="0" x="0" />
          <use id="use5916" xlinkHref="#g6283-6" transform="translate(558.11 52.528)" height="100%" width="100%" y="0" x="0" />
          <use id="use5918" xlinkHref="#g6283-6" transform="matrix(.72487 0 0 .72487 -43.999 229.17)" height="100%" width="100%" y="0" x="0" />
          <use id="use5920" xlinkHref="#g6283-6" transform="translate(484.37 53.033)" height="100%" width="100%" y="0" x="0" />
          <use id="use6109" xlinkHref="#path4291" transform="translate(415.17 -158.59)" height="100%" width="100%" y="0" x="0" />
          <use id="use6111" xlinkHref="#path4291" transform="matrix(2.4486 0 0 2.4486 -1016 -1129.4)" height="100%" width="100%" y="0" x="0" />
          <use id="use6113" xlinkHref="#path4291" transform="matrix(1.78 0 0 1.78 -284.7 -730.35)" height="100%" width="100%" y="0" x="0" />
          <use id="use6115" xlinkHref="#path4291" transform="matrix(1.78 0 0 1.78 -727.14 -665.7)" height="100%" width="100%" y="0" x="0" />
          <use id="use6117" xlinkHref="#path4291" transform="matrix(1.7379 0 0 1.7379 -611.4 -683.2)" height="100%" width="100%" y="0" x="0" />
          <use id="use6119" xlinkHref="#path4291" transform="matrix(2.5321 0 0 2.5321 -1630.9 -1189)" height="100%" width="100%" y="0" x="0" />
          <use id="use6121" xlinkHref="#path4291" transform="matrix(2.0864 0 0 2.0864 -1447.7 -811.93)" height="100%" width="100%" y="0" x="0" />
          <use id="use6123" xlinkHref="#path4291" transform="matrix(2.1479 0 0 2.1479 -1524.3 -931.89)" height="100%" width="100%" y="0" x="0" />
          <use id="use6129" opacity="0.543" xlinkHref="#g6283-6" transform="matrix(.72021 0 0 .72021 371.07 335.99)" height="100%" width="100%" y="0" x="0" />
          <use id="use6131" opacity="0.486" xlinkHref="#g6283-6" transform="matrix(.50038 0 0 .50038 325.49 442.02)" height="100%" width="100%" y="0" x="0" />
          <use id="use6133" opacity="0.229" xlinkHref="#g6283-6" transform="matrix(.48642 0 0 .48642 499.25 617.96)" height="100%" width="100%" y="0" x="0" />
          <use id="use6135" opacity="0.271" xlinkHref="#g6283-6" transform="matrix(.48642 0 0 .48642 135.25 612.9)" height="100%" width="100%" y="0" x="0" />
      </g>
      <path id="path20242" d="m335.25 429.66c154.41 38.618 334.98 61.307 495.33 1.7141 71.235-26.474 260.66-52.463 364.12 1.0474-0.032 23.374 0.096 319.58-0.046 336.51l-1194.4 0.68c0.3621-26.56-0.20242-229.86-0.20242-337.01 15.263-9.5432 165.84-45.301 335.24-2.9346z"
          fill="url(#linearGradient5118)" />
      <use id="use4363" xlinkHref="#g11495" transform="matrix(.22617 0 0 .22617 501.94 324.03)" height="100%" width="100%" y="0" x="0" />
      <use id="use6461" xlinkHref="#g11495" transform="matrix(.38914 0 0 .38914 300.89 216.49)" height="100%" width="100%" y="0" x="0" />
      {/* Sunflower  */}
      <g id="sunflower" transform="matrix(.54223 0 0 .54223 234.47 130.07)">
          <path id="sunShadow" d="m72.725 1278.3c41.425-6.4393 77.114-23.767 108.46-49.346-21.266 36.804-32.932 76.618-31.75 120.46 0.1758 0.1496 38.477-13.425 81.98-75.981-4.9673 35.783-2.1374 71.445 10.67 106.95 0.2443 0.099 42.104-32.416 62.489-132.04 9.5159 51.092 30.874 95.873 69.199 131.61 0.2388-0.021 18.754-39.691 1.6109-120.43 25.22 28.923 55.81 51.146 93.387 64.656 0.2203-0.1185 1.7003-47.792-52.558-120.31 44.063 19.639 89.879 27.31 138.15 18.271 0.1267-0.2082-21.522-39.912-99.724-74.416 42.881 3.7279 84.346-2.6895 123.9-22.756 0.061-0.2262-29.404-29.814-106.88-42.052 37.993-9.27 72.342-26.594 101.92-54.46-0.01-0.2659-47.289-26.137-148.53-4.2099 6.5574-5.4455 12.625-10.799 18.229-16.038 78.239-60.979 211.17-164.32 217.06-167.03 14.768-6.7972-25.362-50.172-39.125-44.765l-155.93 124.31c-27.185 9.9826-51.716 25.204-74.101 44.662 44.735-79.126 37.12-126.37 36.886-126.46-33.207 17.037-59.387 40.835-80.053 69.937 4.2279-34.457 1.1594-68.8-11.176-103-0.2527-0.1026-44.989 34.654-64.486 142.4-16.26-105.9-58.97-141.44-59.22-141.35-19.55 49.03-19.62 99-6.14 149.64-61.86-76.95-112.5-86.91-112.67-86.71 5.609 46.46 25.03 86.25 54.52 121.16-92.57-27.96-139.21-8.25-139.25-7.99 23.298 26.883 51.045 46.102 82.327 59.234-31.573 1.1879-62.282 8.566-91.921 23.601-0.065 0.2405 33.247 33.683 122.18 44.16-42.202 14.966-78.42 39.009-106.5 75.396 0.045 0.2439 44.159 15.999 128.33-12.305-64.124 59.494-71.482 105.1-71.291 105.26zm316.98-223.55a119.68 107.99 51.448 0 0 -1.379 -2.8816c0.1149-0.079 0.2324-0.1562 0.347-0.2348 0.3545 1.0109 0.6978 2.0513 1.032 3.1164z"
              fill-opacity=".082418" fill="#232323" />
          <g id="g6539">
              <path id="path10637" d="m337.05 507.76c13-0.5574 26 4.6729 39-5.5 1.0374-9.5782 2.6196-19.217-8-27.5" stroke-opacity=".29412" stroke="#000" stroke-width="1px" fill="none" />
              <path id="path10235" d="m615.71 573.53c-105.63 35.808-129.48-56.858-194.22-85.291-51.485-42.358-146.69 7.4452-132.02 10.185 34.103 2.728 282.37 168.59 326.24 75.106z" fill="#3fa92c" />
              <path id="path10237" d="m287.55 497.76c4.5-0.5 59.5-29.5 130.5 30 153.93 100.95 167.79 52.202 193 55" stroke-opacity=".29255" stroke="#000" stroke-width="1px" fill="none" />
              <path id="path10639" d="m408.05 484.26c5.5208 13.604 3.2035 23.849-0.5 33.5-11.152 10.88-20.588 8.038-30.5 9" stroke-opacity=".29412" stroke="#000" stroke-width="1px" fill="none" />
              <ellipse id="path10651" fill-opacity=".13830" rx="9.2927" ry="5.1467" transform="rotate(14.612)" cy="378.59" cx="463.43" fill="#fff" />
              <ellipse id="path10657"  fill-opacity=".13830" rx="9.2927" ry="5.1467" transform="rotate(14.612)" cy="426.55" cx="687.78" />
          </g>
          <g id="g10865" transform="matrix(.84790 -.53016 .53016 .84790 449.59 -7258.5)">
              <path id="path10233" d="m-3991.5 6747.4c124.67 113.48 251.56 159.29 298.74 75.052-78.298 26.627-64.07-30.149-102.02-32.248-55.172-3.0516-152.93-44.22-180.81-65.49-7.7584-5.9189-1.12 18.846-15.912 22.686z" fill="#3fa92c" />
              <path id="path10256" d="m-3984 6736.4c40.743 28.428 106.8 43.552 156.71 86.394 33.121 28.429 112.31 26.594 132.29 1.1058" stroke-opacity=".29255" stroke="#000" stroke-width="1px" fill="none" />
              <path id="path10641" d="m-3788.5 6802.9s-26 1.5-40 18c1.6278 8.2466-3.8391 16.355 2 28" stroke-opacity=".29412" stroke="#000" stroke-width="1px" fill="none" />
              <path id="path10643" d="m-3832 6792.4c-13.083-2.4157-24.063-1.8881-30.5 5-7.0134 9.6667-5.5616 19.333-2 29" stroke-opacity=".29412" stroke="#000" stroke-width="1px" fill="none" />
              <path id="path10645" d="m-3764 6826.4c-11.339 1.5383-18.32 5.8002-19 14 0.5286 8.7357 7.426 14.287 13 20.5" stroke-opacity=".29412" stroke="#000" stroke-width="1px" fill="none" />
              <path id="path10647" d="m-3737.5 6834.4s-14.5 2.5-12.5 9c2 9.5 13 9.5 13 9.5" stroke-opacity=".29412" stroke="#000" stroke-width="1px" fill="none" />
              <path id="path10649" d="m-3716 6830.4s-8.5 1.5-5.5 9.5c0 4 10 2 10 2" stroke-opacity=".29412" stroke="#000" stroke-width="1px" fill="none" />
              <ellipse id="path10653"  fill-opacity=".13830" rx="9.2927" ry="5.1467" transform="rotate(14.612)" cy="7586" cx="-1951.5" fill="#fff" />
              <ellipse id="path10655"  fill-opacity=".13830" rx="9.2927" ry="5.1467" transform="rotate(14.612)" cy="7545.8" cx="-2102.5" />
          </g>
          <g id="sunStem">
              <path id="path10240" d="m606.29 440.76 61.588-1.0265v392.11c-4.9603 16.121-72.181 21.085-67.746 3.0793 2.5034-10.164 6.1587-394.16 6.1587-394.16z" fill="#3fa92c" />
              <path id="path10248" d="m662.17 440.76 5.7002-1.0265v394.11c-44.22-102.74-11.26-271.15-5.7-393.08z" fill-opacity=".24725" fill="#fff" />
              <path id="path10251" d="m604.08 440.76 8.124-1.0265c42.044-124.87 24.07 300.45-8.9364 395.19 0.2708-10.264 0.8124-394.16 0.8124-394.16z" fill-opacity=".13187" />
          </g>
          {/* Flower Head */}
          <g id="flowerHead">
              <use id="use6482" xlinkHref="#g10287" transform="matrix(.70935 -.70486 .70486 .70935 -39.739 548.25)" height="100%" width="100%" y="0" x="0" />
              <use id="use6484" xlinkHref="#g10287" transform="matrix(-.70159 .71258 -.71258 -.70159 1319 96.89)" height="100%" width="100%" y="0" x="0" />
              <use id="use6498" xlinkHref="#g10287" transform="matrix(-.36145 .93239 -.93239 -.36145 1174.5 -150.5)" height="100%" width="100%" y="0" x="0" />
              <use id="use6492" xlinkHref="#g10287" transform="matrix(-.92550 .37874 -.37874 -.92550 1363.8 376.36)" height="100%" width="100%" y="0" x="0" />
              <use id="use6444" xlinkHref="#g10287" transform="matrix(-1 0 0 1 1289.8 0)" height="100%" width="100%" y="0" x="0" />
              <use id="use6494" xlinkHref="#g10287" transform="matrix(-.91734 -.39811 .39811 -.91734 1108.2 874.72)" height="100%" width="100%" y="0" x="0" />
              <use id="use6488" xlinkHref="#g10287" transform="matrix(-.71484 -.69929 .69929 -.71484 880.53 1003.7)" height="100%" width="100%" y="0" x="0" />
              <use id="use6480" xlinkHref="#g10287" transform="matrix(.020268 -.99979 .99979 .020268 309.59 952.55)" height="100%" width="100%" y="0" x="0" />
              <use id="use6504" xlinkHref="#g10287" transform="matrix(.41272 -.91086 .91086 .41272 87.165 770.71)" height="100%" width="100%" y="0" x="0" />
              <use id="use6502" xlinkHref="#g10287" transform="matrix(-.35888 -.93338 .93338 -.35888 571.51 1031.9)" height="100%" width="100%" y="0" x="0" />
              <use id="use6500" xlinkHref="#g10287" transform="matrix(.94056 .33963 -.33963 .94056 147.8 -199.87)" height="100%" width="100%" y="0" x="0" />
              <use id="use6490" xlinkHref="#g10287" transform="matrix(.91604 -.40109 .40109 .91604 -75.645 285.73)" height="100%" width="100%" y="0" x="0" />
              <g id="g10287" transform="matrix(-.018098 -.99984 .99984 -.018098 227.2 712.22)">
                  <path id="path10289" d="m350.14 300.17c-12.42-155.78 39.58-214.91 39.92-214.84 38.707 65.357 42.753 137.89 25.404 214.84-34.036-7.2321-47.863-3.0648-65.323-0.00001z" fill="#e4e748" />
                  <path id="path10291" d="m350.14 300.17c-12.42-155.78 39.58-214.91 39.92-214.84-29.52 91.27-41.11 192.33-13.17 211.98-34.036-7.2321-9.2914-0.20762-26.752 2.8571z" fill="#d9dd1d" />
                  <path id="path10293" d="m395.85 300.17c47.439-66.556-3.642-214.91-5.7945-214.84 38.707 65.357 42.753 137.89 25.404 214.84-34.036-7.2321-2.1485-3.0648-19.609-0.00001z" fill="#edef85" />
              </g>
              <use id="use6496" xlinkHref="#g10287" transform="matrix(.40632 .91373 -.91373 .40632 681.37 -385.92)" height="100%" width="100%" y="0" x="0" />
              <use id="use6486" xlinkHref="#g10287" transform="matrix(.73126 .68210 -.68210 .73126 393.16 -353.27)" height="100%" width="100%" y="0" x="0" />
              <use id="use6478" xlinkHref="#g10287" transform="matrix(.013862 .99990 -.99990 .013862 958.25 -315)" height="100%" width="100%" y="0" x="0" />
              <ellipse id="ellipse10391" rx="136.52" ry="123.17" cy="323.74" cx="641.18" fill="#e69142" />
              <g id="g10395" transform="matrix(.19367 0 0 .19367 1582.6 -318.84)">
                  <path id="path10397" d="m-5209.6 3414.7c237.55 52.585 468.38 93.357 705.93-23.387-1.7791 65.605-43.203 155.2-85.841 221.51-237.85 131.49-337.81 77.852-551.51-14.834-27.784-36.727-74.829-70.361-68.578-183.29z" fill="#464646" />
                  <rect id="rect10399"  transform="matrix(.98837 .15208 -.11610 .99324 0 0)" height="63.148" width="21.982" y="4048.8" x="-4102.2" fill="#e5e5e5" />
                  <rect id="rect10401"  transform="matrix(.99654 .083136 -.21075 .97754 0 0)" height="61.448" width="38.511" y="3922.6" x="-3774.6" fill="#e5e5e5" />
                  <path id="path10403" d="m-4649.5 3646.3c15.782 2.0662 47.229-15.957 53.073-19.085l6.8622-108.33c-20.879 3.8039-52.432 18.925-55.553 19.755-0.3487 30.249-3.3995 77.422-4.3823 107.66z" stroke="#464646" stroke-width=".99093" fill="#e5e5e5" />
                  <path id="path10405" d="m-4697.9 3660.5c15.782 2.0662 42.392-9.9106 48.236-13.038l3.9599-109.3c-20.396-2.0006-48.321 12.153-51.442 12.983-2.0665 34.1 1.9493 72.445-0.7545 109.35z" stroke="#464646" stroke-width=".99093" fill="#e5e5e5" />
                  <path id="path10407" d="m-4778.5 3567.8 0.285 109.95c17.438 5.217 80.489-11.02 80.361-17.022l1.1983-106.23c-3.4083-0.8507-62.853-2.6811-81.845 13.298z" stroke="#464646" stroke-width="1.3025" fill="#e5e5e5" />
                  <path id="path10409" d="m-4860.6 3570.9-2.261 111.59c19.648 5.9136 55.564-0.086 83.196-2.156l1.5037-105.62c-21.374-18.436-54.091-16.912-82.439-3.8171z" stroke="#464646" stroke-width="1.4886" fill="#e5e5e5" />
                  <path id="path10411" d="m-4946.2 3570.4-1.2936 103.37c21.186 13.602 59.003 13.718 85.615 6.5509l0.5363-100.78c5.3205-17.265-46.813-24.82-84.858-9.138z" stroke="#464646" stroke-width="1.4886" fill="#e5e5e5" />
                  <path id="path10413" d="m-5021.7 3555.7 3.5436 113.29c19.649 5.9137 41.537 9.5876 69.169 7.5182l1.5037-105.62c-26.231-20.297-51.99-14.173-74.216-15.184z" stroke="#464646" stroke-width="1.4886" fill="#e5e5e5" />
                  <path id="path10415" d="m-5075.7 3546.8 2.8733 106.21c14.573 6.9033 48.68 15 54.525 11.873l-3.2958-109.54c-21.537-8.9375-45.311-12.396-54.102-8.5425z" stroke="#464646" stroke-width=".99093" fill="#e5e5e5" />
                  <rect id="rect10417"  transform="matrix(.98238 -.18688 .46694 .88429 0 0)" height="91.982" width="33.939" y="2600.3" x="-6514.2" fill="#e5e5e5" />
                  <path id="path10419" d="m-5133.7 3535 3.8408 102.34c14.331 8.5963 51.825 17.419 57.669 14.291l-3.7794-104.94c-24.553-8.9109-52.992-16.154-57.73-11.687z" stroke="#464646" stroke-width=".99093" fill="#e5e5e5" />
                  <ellipse id="ellipse10421" opacity="0.443"  rx="6.9433" transform="translate(0 1182.3)" ry="8.4409" cy="2481.3" cx="-4876.9" fill="#fff" />
                  <ellipse id="ellipse10423" opacity="0.443"  rx="6.9433" transform="translate(0 1182.3)" ry="14.666" cy="2451.1" cx="-4793.1" fill="#fff" />
                  <path id="path10425" d="m-4946.4 3653.7c25.259-0.7895 44.184 5.0186 84.222-11.166-15.326-0.8751-77.06 2.7211-84.222 11.166z" fill-rule="evenodd" fill-opacity=".085366" />
                  <path id="path10427" d="m-4859.9 3582.7 83.972-2.7087c-1.3935 15.179-56.551 27.076-83.972 2.7087z" fill-opacity=".085366" fill-rule="evenodd" />
                  <path id="path10429" d="m-4776.8 3579.2 83.761-6.5383c-0.6993 15.226-55.256 29.629-83.761 6.5383z" fill-rule="evenodd" fill-opacity=".085366" />
                  <path id="path10431" d="m-5018.5 3640.6c22.904 6.7343 46.097 11.656 70.991 13.084-1.2967-9.6711-47.316-21.096-70.991-13.084z" fill-opacity=".085366" fill-rule="evenodd" />
                  <path id="path10433" d="m-4572.2 3426.2-7.6272 110.34c15.119 1.9794 45.619-19.508 51.218-22.504l18.017-115.12c-19.366 1.6914-56.436 23.235-61.607 27.293z" stroke="#464646" stroke-width=".94932" fill="#e5e5e5" />
                  <path id="path10435" d="m-4635.4 3556.9c16.479 2.1574 49.315-16.662 55.418-19.928l7.1653-113.11c-21.802 3.9719-54.748 19.761-58.007 20.627-0.3641 31.585-3.5497 80.842-4.5759 112.41z" stroke="#464646" stroke-width="1.0347" fill="#e5e5e5" />
                  <path id="path10437" d="m-4685.9 3571.7c16.479 2.1574 44.265-10.348 50.367-13.614l4.1348-114.12c-21.297-2.089-50.455 12.69-53.714 13.556-2.1578 35.606 2.0354 75.645-0.7878 114.18z" stroke="#464646" stroke-width="1.0347" fill="#e5e5e5" />
                  <path id="path10439" d="m-4770.1 3474.9 0.2975 114.81c18.209 5.4474 84.044-11.507 83.911-17.774l1.2513-110.92c-3.5589-0.8883-65.629-2.7995-85.46 13.886z" stroke="#464646" stroke-width="1.36" fill="#e5e5e5" />
                  <path id="path10441" d="m-4855.7 3478.2-2.3608 116.52c20.516 6.1749 58.019-0.09 86.871-2.2512l1.5701-110.28c-22.318-19.25-56.48-17.659-86.08-3.9857z" stroke="#464646" stroke-width="1.5543" fill="#e5e5e5" />
                  <path id="path10443" d="m-4945.1 3477.7-1.3507 107.94c22.122 14.202 61.609 14.324 89.396 6.8402l0.56-105.23c5.5555-18.028-48.88-25.916-88.606-9.5416z" stroke="#464646" stroke-width="1.5543" fill="#e5e5e5" />
                  <path id="path10445" d="m-5023.9 3462.2 3.7001 118.29c20.516 6.1749 43.372 10.011 72.224 7.8503l1.5701-110.28c-27.39-21.193-54.287-14.799-77.494-15.855z" stroke="#464646" stroke-width="1.5543" fill="#e5e5e5" />
                  <path id="path10447" d="m-5080.3 3453 3.0002 110.9c15.216 7.2082 50.831 15.663 56.933 12.397l-3.4413-114.38c-22.489-9.3323-47.312-12.943-56.492-8.9198z" stroke="#464646" stroke-width="1.0347" fill="#e5e5e5" />
                  <path id="path10449" d="m-5140.9 3440.6 4.0104 106.86c14.964 8.976 54.114 18.188 60.216 14.923l-3.9464-109.58c-25.638-9.3045-55.333-16.867-60.28-12.203z" stroke="#464646" stroke-width="1.0347" fill="#e5e5e5" />
                  <rect id="rect10451"  height="89.095" width="21.92" y="3418.6" x="-5210" fill="#e5e5e5" />
                  <path id="path10453" d="m-5201.5 3427.3 7.7984 101.05c10.418 8.2184 50.073 21.219 56.175 17.953l-3.6938-105.79c-23.653-7.1398-38.673-14.079-60.28-13.213z" stroke="#464646" stroke-width="1.0347" fill="#e5e5e5" />
                  <path id="path10455" d="m-4864.2 3438.2c-177.72-2.3483-270.53-59.076-347.58-28.504 1.7351 96.442 279.25 89.637 348.68 80.306 94.525 3.8198 362.71-16.34 361.4-101.12-79.409-26.78-257.12 40.202-362.51 49.317z" fill-rule="evenodd" fill="#edb079" />
                  <ellipse id="ellipse10457" opacity="0.443"  rx="7.25" transform="matrix(0 .51234 -1.6017 0 -1067 6084.5)" ry="8.8137" cy="2392.6" cx="-4872.8" fill="#fff" />
                  <ellipse id="ellipse10459" opacity="0.443"  rx="7.25" transform="translate(0 1182.3)" ry="15.314" cy="2361.1" cx="-4785.2" fill="#fff" />
                  <ellipse id="ellipse10461" opacity=".275"  rx="7.25" ry="15.314" transform="matrix(.33794 .94117 -.94117 .33794 0 1182.3)" cy="5173.1" cx="519.47" fill="#fff" />
                  <path id="path10463" d="m-4850.7 3719.7c-180.03 0.9159-424.19-101.87-357.8-311.04-6.5771 221.22 277.7 278.04 354.91 229.3 75.812 47.18 295.11 31.553 351.83-243.31-5.6005 249.86-182.17 333.07-348.94 325.05z" fill-rule="evenodd" fill="#edb079" />
                  <path id="path10465" d="m-5120.9 3619.2c236.31 145.14 389.96 102.16 517.6 0-196.47 114.87-358.15 62.792-517.6 0z" fill-opacity=".31707" fill-rule="evenodd" fill="#fff" />
                  <path id="path10467" d="m-5150.6 3402.8c9.2979 26.723 275.87 60.608 274.36 42.426-75.066 20.608-175.81 43.616-274.36-42.426z" fill-opacity=".31707" fill-rule="evenodd" fill="#fff" />
                  <path id="path10469" d="m-4944.1 3494.7 86.806-4.7033c0.01 11.257-54.712 20.559-86.806 4.7033z" fill-opacity=".085366" fill-rule="evenodd" />
                  <path id="path10471" d="m-4855 3490.5 87.681-2.8283c-1.3182 10.238-58.73 15.215-87.681 2.8283z" fill-rule="evenodd" fill-opacity=".085366" />
                  <path id="path10473" d="m-4769.7 3487.9 84.809-8.9485c-10.862 10.38-51.725 16.639-84.809 8.9485z" fill-opacity=".085366" fill-rule="evenodd" />
                  <path id="path10475" d="m-5022.6 3492.6c25.733 1.7224 49.996 3.4632 75.407 2.2966-0.3499 10.263-47.726 11.033-75.407-2.2966z" fill-rule="evenodd" fill-opacity=".085366" />
                  <path id="path10477" d="m-5079.7 3487.3c23.435 2.9598 32.318 4.5239 56.138 6.0091-10.249 4.2523-41.362-0.8115-56.138-6.0091z" fill-opacity=".085366" fill-rule="evenodd" />
                  <path id="path10479" d="m-5139.5 3475c25.209 5.4486 34.464 9.6409 59.774 12.184-12.446 1.9708-39.101-2.2169-59.774-12.184z" fill-rule="evenodd" fill-opacity=".085366" />
                  <path id="path10481" d="m-5199.9 3440.2c17.394 19.585 36.699 26.043 59.591 34.115-12.515 2.9774-47.084-11.169-59.591-34.115z" fill-opacity=".085366" fill-rule="evenodd" />
                  <path id="path10483" d="m-4684.1 3479c19.583-2.9082 37.18-5.8765 51.273-8.951-7.6667 4.6477-29.182 11.091-51.273 8.951z" fill-rule="evenodd" fill-opacity=".085366" />
                  <path id="path10485" d="m-4631 3469.4c19.546-4.0879 37.482-9.3564 55.886-15.239-6.5633 7.691-33.797 15.889-55.886 15.239z" fill-opacity=".085366" fill-rule="evenodd" />
                  <path id="path10487" d="m-4573.9 3453.6c22.884-8.587 44.816-18.049 59.484-34.195-3.4946 10.806-33.848 38.506-59.484 34.195z" fill-rule="evenodd" fill-opacity=".085366" />
                  <ellipse id="ellipse10489" opacity="0.443"  rx="7.25" ry="15.314" transform="matrix(.41379 0 0 .41379 -2722.4 2542.4)" cy="2361.1" cx="-4785.2" fill="#fff" />
                  <ellipse id="ellipse10491" opacity="0.443"  rx="7.25" transform="matrix(.41379 0 0 .41379 -2625.9 2600.9)" ry="15.314" cy="2361.1" cx="-4785.2" fill="#fff" />
                  <ellipse id="ellipse10493" opacity="0.443"  rx="7.25" ry="15.314" transform="matrix(.41379 0 0 .41379 -3049.9 2616.9)" cy="2361.1" cx="-4785.2" fill="#fff" />
                  <ellipse id="ellipse10495" opacity="0.443"  rx="7.25" transform="matrix(.41379 0 0 1.5912 -3166.2 -246.13)" ry="15.314" cy="2361.1" cx="-4785.2" fill="#fff" />
                  <path id="path10497" d="m-4777.4 3658.4c23.226-0.7189 37.348 1.792 79.472-10.916-13.427 4.251-70.89 6.3054-79.472 10.916z" fill-opacity=".085366" fill-rule="evenodd" />
                  <path id="path10499" d="m-4696.7 3646.8c14.274-2.7498 28.141-11.448 47.654-20.707-8.768-0.5007-43.557 15.876-47.654 20.707z" fill-rule="evenodd" fill-opacity=".085366" />
                  <path id="path10501" d="m-4648 3625.5c22.934-12.296 51.195-39.025 53.842-42.981-8.8719 1.2626-49.877 40.413-53.842 42.981z" fill-opacity=".085366" fill-rule="evenodd" />
                  <path id="path10503" d="m-4593.2 3581.4c13.92-10.174 27.223-31.6 34.75-42.097-5.7239 1.4019-30.34 37.46-34.75 42.097z" fill-rule="evenodd" fill-opacity=".085366" />
                  <path id="path10505" d="m-5073.4 3619.5c14.93 6.0535 31.031 15.737 54.116 21.084-12.547-9.1711-32.566-19.221-54.116-21.084z" fill-rule="evenodd" fill-opacity=".085366" />
                  <path id="path10507" d="m-5131.6 3581.6c15.529 12.984 39.347 29.406 57.991 37.834-1.2967-9.6711-50.69-38.971-57.991-37.834z" fill-opacity=".085366" fill-rule="evenodd" />
                  <path id="path10509" d="m-4853.5 3638.3c20.956 11.928 44.059 18.615 74.282 20.143-5.3965-1.7736-58.483-21.699-74.282-20.143z" fill-rule="evenodd" fill-opacity=".085366" />
              </g>
              <ellipse id="ellipse10511" rx="29.512" ry="11.037" transform="rotate(36.517)" cy="-236.22" cx="729.12" fill="#e7984e" />
              <path id="path10393" d="m691.99 412.31c65.365-18.847 24.593 34.58-50.804 34.603-75.55 0.023-131.28-47.371-136.52-123.17-5.6781-82.106 117-146.28 81.162-92.46-116.48 174.93 53.922 196.09 106.16 181.03z" fill-opacity=".098901" />
              <g id="g10597" transform="matrix(-2.0494 0 0 2.0494 2625.9 -7449.4)">
                  <path id="path10599" fill="#fff" d="m1023.3 3774.9c0 4.7864-12.253 8.6666-27.368 8.6666s-27.368-3.8802-27.368-8.6666c0-4.7865 12.253-8.6666 27.368-8.6666s27.368 3.8801 27.368 8.6666z" />
                  <path id="path10601" d="m995.94 3766.2c-1.8893 0-3.7268 0.07-5.5084 0.1851h-0.0142c-2.9307 1.638-4.9106 4.7592-4.9106 8.3551 0 3.8169 2.2352 7.1146 5.4657 8.654 1.6154 0.094 3.2662 0.1424 4.9675 0.1424 1.0337 0 2.0563-0.021 3.0602-0.057 3.3292-1.5006 5.6507-4.8506 5.6507-8.7395 0-3.6707-2.0642-6.8466-5.0956-8.4547-1.1839-0.05-2.3888-0.086-3.6153-0.086z"
                      fill="#936218" />
                  <path id="path10603" d="m999.14 3774.9c0 2.1181-1.717 3.8351-3.8351 3.8351s-3.8351-1.717-3.8351-3.8351 1.717-3.8351 3.8351-3.8351 3.8351 1.717 3.8351 3.8351z" fill="#0d0d0d" />
                  <path id="path10605" d="m994.94 3770.8c0 0.8589-0.69629 1.5552-1.5552 1.5552s-1.5552-0.6963-1.5552-1.5552 0.69629-1.5552 1.5552-1.5552c0.85892 0 1.5552 0.6963 1.5552 1.5552z" fill="#fff" />
                  <path id="path10607" opacity=".39024" d="m994.11 3781.3c6.2502-0.7396 8.0449-3.8554 7.1526-8.4043-0.8425-1.509 1.9783-4.2225 2.3246 1.8776-0.4529 5.9045-3.7625 7.5881-7.5102 8.0467-4.1285 0.3399-5.6845-1.4109-1.967-1.52z" fill="#f0f2fd" />
                  <path id="leftEyeLidSun" d="m1023.3 3774.9c0 0.5842-12.253-6.8903-27.368-6.8903s-27.215 7.4612-27.368 6.8903c-1.2388-4.6234 12.253-8.6667 27.368-8.6667s27.368 3.8802 27.368 8.6667z" fill="#d4d4d4" />
              </g>
              <use id="use6506" xlinkHref="#g10597" transform="translate(115.52)" height="100%" width="100%" y="0" x="0" />
              <g id="g6516">
                  <circle id="circle10633" fill-opacity=".32967" transform="rotate(78.793)" cy="-450.12" cx="459.17" r="2.1587" />
                  <use id="use6508" xlinkHref="#circle10633" transform="matrix(.78831 0 0 .78831 115.38 81.486)" height="100%" width="100%" y="0" x="0" />
                  <use id="use6510" xlinkHref="#circle10633" transform="matrix(1.4234 0 0 1.4234 -216.32 -154.02)" height="100%" width="100%" y="0" x="0" />
                  <use id="use6512" xlinkHref="#circle10633" transform="matrix(1.8891 0 0 1.8891 -470.55 -329.89)" height="100%" width="100%" y="0" x="0" />
                  <use id="use6514" xlinkHref="#circle10633" transform="matrix(1.5927 0 0 1.5927 -304.2 -207.25)" height="100%" width="100%" y="0" x="0" />
              </g>
              <use id="use6523" xlinkHref="#g6516" transform="matrix(-1 0 0 1 1267.6 19.193)" height="100%" width="100%" y="0" x="0" />
              <use id="use6525" xlinkHref="#g6516" transform="translate(40.214 -128.68)" height="100%" width="100%" y="0" x="0" />
              <use id="use6527" xlinkHref="#g6516" transform="translate(162.32 -136)" height="100%" width="100%" y="0" x="0" />
              <use id="use6529" xlinkHref="#g6516" transform="translate(227.39 -47.525)" height="100%" width="100%" y="0" x="0" />
              <use id="use6531" xlinkHref="#g6516" transform="matrix(-1 0 0 1 1248.9 29.246)" height="100%" width="100%" y="0" x="0" />
              <use id="use6533" xlinkHref="#g6516" transform="translate(-2.2339 -49.44)" height="100%" width="100%" y="0" x="0" />
              <use id="use6535" xlinkHref="#g6516" transform="matrix(1.2059 0 0 1.2059 -90.957 -66.201)" height="100%" width="100%" y="0" x="0" />
              <use id="use6537" xlinkHref="#g6516" transform="matrix(-1 0 0 1 1129 -134.53)" height="100%" width="100%" y="0" x="0" />
          </g>
      </g>
     
     {/* what are the these */}

      <g id="g6283" transform="matrix(.50111 0 0 .50111 647.96 -132.24)">
          <path id="path20618" d="m582.38 520.48c-16.472 0-30.153 7.8446-33.094 18.219-11.188 2.8178-19.375 11.905-19.375 22.688 0 8.2756 4.8226 15.569 12.125 19.781-0.0527 0.62735-0.0937 1.2657-0.0937 1.9062 0 12.766 10.679 23.094 23.844 23.094 5.7029 0 10.929-1.9169 15.031-5.1562 4.4967 1.7574 9.6214 2.75 15.062 2.75 8.1187 0 15.517-2.2177 21.188-5.875 1.4535 0.36793 2.9811 0.59375 4.5625 0.59375 9.4411 0 17.094-7.013 17.094-15.656 0-4.0934-1.7211-7.8049-4.5312-10.594 4.3082-4.2177 6.9375-9.8936 6.9375-16.125 0-13.031-11.522-23.594-25.75-23.594-1.0053 0-1.9934 0.0536-2.9688 0.15625-5.5863-7.2363-16.948-12.188-30.031-12.188z"
              fill="#fff" />
          <g id="g6061" stroke-opacity=".21939" transform="translate(-3.9286 -.71429)" stroke="#000" stroke-width=".48518px" fill="none">
              <path id="path13367" d="m557.13 575.16c5.1228 10.326 13.434 13.879 25.538 9.3762" />
              <path id="path13369" d="m612.08 542.08c10.29 5.1933 13.787 13.529 9.2014 25.602" />
              <path id="path13371" d="m551.17 561.31c0.98253-11.485 7.4132-17.837 20.326-18.083" />
              <path id="path13373" d="m578.63 578.66c5.1228 10.326 13.434 13.879 25.538 9.3763" />
              <path id="path13375" d="m615.85 565.47c10.175 5.4152 13.49 13.824 8.644 25.795" />
          </g>
      </g>
      <use id="use4206" opacity="0.329" xlinkHref="#g6283" transform="matrix(.76660 0 0 .76660 -515.54 133.1)" height="100%" width="100%" y="0" x="0" />
      <use id="use4208" xlinkHref="#g6283" transform="translate(-605.63 -62.996)" height="100%" width="100%" y="0" x="0" />
      <use id="use4367" xlinkHref="#g11495" transform="matrix(.35448 0 0 .35448 31.056 240.79)" height="100%" width="100%" y="0" x="0" />
      <use id="use6455" xlinkHref="#sunflower" transform="matrix(.49633 0 0 .49633 -93.58 162.18)" height="100%" width="100%" y="0" x="0" />
      <g id="worm">
          <path id="path7706" d="m1086 737.09s-50.941 58.519-255.22 16.624c-177.02-36.304-255.01 14.202-508.84 42.754-253.82 28.552-667.32 194.1-847.46 208.49-180.13 14.391-281.85-7.5861-282.77-15.786-0.9224-8.1999 90.539-81.146 269.37-107.13 178.83-25.988 545.48-35.865 766.22-164.38 233.67-136.04 432.17 1.7946 612.7-10.501 180.29-12.279 210.01-30.639 246 29.928z"
              fill-opacity=".071429" />
          <path id="path7708" d="m-805.63 331-14.62-275.28c4.7445-167.53 193.2-120.42 205.82-6.1398l20.352 600.43c29.103 157.06 100.95 88.41 130.05-3.358 121.88-243.77 349.02-224.99 481.1-147.75 172.43 106.35 261.11 162.09 453.33 36.938 211.29-140.5 420.45 36.509 468.44 70.518 118.25 80.027 120.51 162.51-3.71 108.75-167.5-89.16-287.17-176.12-460.38-9.81-123.12 118.21-356.86 84.48-465.79 13.25-143.95-103.76-283.4-95.92-331.78 45.89-51.19 150.06-138.76 193.17-216.23 193.73-128.25 0.92-260.31-118.52-263.97-251.91z"
              fill-rule="evenodd" fill="#ffcba4" />
          <ellipse id="ellipse7710" fill-opacity=".093407" rx="11.193" ry="13.432" cy="-10.112" cx="-681.46" fill="#fff" />
          <path id="path7850" d="m-775.76 188.85c44.39 37.992 84.612 39.513 120.05-0.8395" stroke-opacity=".19231" stroke="#000" stroke-linecap="round" stroke-width="5.315" fill="none" />
          <path id="path7852" d="m-810.18 253.49c44.39 37.992 168.56 45.389 204 5.0369" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path7854" d="m-810.18 711.02c46.054 44.65 207.23 85.043 228.34-12.592" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path7856" d="m-173.95 646.75c44.65-50.803 53.426-160.8-26.401-197.35" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path7858" d="m-610.91 591.83c2.4526-16.397-19.109-499.16-19.684-540.07-0.7219-18.094 4.0009-69.219 15.728-1.9945l19.512 543.02c0.7714 78-13.667 22.215-15.556-0.9496z" fill-opacity=".26636" fill-rule="evenodd" fill="#fff" />
          <ellipse id="ellipse7860" fill-opacity=".22527" rx="25.959" ry="9.3586" cy="199.58" cx="-717.28" />
          <g id="g7862" fill-opacity=".22527" transform="matrix(.83950 0 0 .83950 -1576.6 1520.8)">
              <ellipse id="ellipse7864" rx="3.4745" ry="4.2727" cy="-1590.9" cx="1067.7" />
              <ellipse id="ellipse7866" rx="1.759" ry="2.1631" cy="-1602.6" cx="1066" />
              <ellipse id="ellipse7868" rx="1.759" ry="2.1631" cy="-1593" cx="1075.6" />
              <ellipse id="ellipse7870" rx="1.759" ry="2.1631" cy="-1596" cx="1060.3" />
          </g>
          <g id="g7872" fill-opacity=".22527" transform="matrix(.83950 0 0 .83950 -1536.3 1593.4)">
              <ellipse id="ellipse7874" rx="3.4745" ry="4.2727" cy="-1590.9" cx="1067.7" />
              <ellipse id="ellipse7876" rx="1.759" ry="2.1631" cy="-1602.6" cx="1066" />
              <ellipse id="ellipse7878" rx="1.759" ry="2.1631" cy="-1593" cx="1075.6" />
              <ellipse id="ellipse7880" rx="1.759" ry="2.1631" cy="-1596" cx="1060.3" />
          </g>
          <g id="g7882" fill-opacity=".22527" transform="matrix(.83950 0 0 .83950 -1560.5 1359.5)">
              <ellipse id="ellipse7884" rx="3.4745" ry="4.2727" cy="-1590.9" cx="1067.7" />
              <ellipse id="ellipse7886" rx="1.759" ry="2.1631" cy="-1602.6" cx="1066" />
              <ellipse id="ellipse7888" rx="1.759" ry="2.1631" cy="-1593" cx="1075.6" />
              <ellipse id="ellipse7890" rx="1.759" ry="2.1631" cy="-1596" cx="1060.3" />
          </g>
          <g id="g7892" fill-opacity=".22527" transform="matrix(4.7851 0 0 4.3378 12783 1869.5)">
              <g id="g7894" transform="matrix(-.83950 0 0 -.83950 -1927.7 -1685.1)">
                  <ellipse id="ellipse7896" rx="3.4745" ry="4.2727" cy="-1590.9" cx="1067.7" />
                  <ellipse id="ellipse7898" rx="1.759" ry="2.1631" cy="-1602.6" cx="1066" />
                  <ellipse id="ellipse7900" rx="1.759" ry="2.1631" cy="-1593" cx="1075.6" />
                  <ellipse id="ellipse7902" rx="1.759" ry="2.1631" cy="-1596" cx="1060.3" />
              </g>
              <g id="g7904" transform="matrix(-.97834 0 0 -.97834 -1770.1 -1913.4)">
                  <ellipse id="ellipse7906" rx="3.4745" ry="4.2727" cy="-1590.9" cx="1067.7" />
                  <ellipse id="ellipse7908" rx="1.759" ry="2.1631" cy="-1602.6" cx="1066" />
                  <ellipse id="ellipse7910" rx="1.759" ry="2.1631" cy="-1593" cx="1075.6" />
                  <ellipse id="ellipse7912" rx="1.759" ry="2.1631" cy="-1596" cx="1060.3" />
              </g>
          </g>
          <path id="path7914" d="m646.05 614.69c25.715-48.033 38.948-93.376-3.5307-129.94" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path7916" d="m217.05 778.37c43.615-48.133 78.989-128.63 22.369-173.68" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path7918" d="m917.75 704.64c30.428-18.196 53.376-14.954 51.051-73.629" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path7920" d="m968.66 725.85c34.67-11.125 39.234-14.954 42.566-55.244" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path7922" d="m-329.87 717.33c-30.189-96.27 319.42-147.53 347.58-29.71 14.985 54.19-52.63 2.958-72.937-8.4941-110.29-51.789-178.09-32.78-228.63 15.012-24.644 38.143-32.112 37.83-46.008 23.192z" fill-rule="evenodd" fill-opacity=".074766" />
          <path id="path7924" d="m-291 471.79c17.7-11.806 142.03-48.784 250.39 0.2903 6.135-0.722 45.209 19.529 1.4159 12.256-130.17-63.83-173.41 15.298-270.11 14.947-33.942-0.036 6.0985-23.11 18.302-27.494z" fill-opacity=".33516" fill-rule="evenodd" fill="#fff"
          />
          <path id="path7926" d="m490.8 654.11c-30.189-96.27 336.39-121.02 364.55-3.1932 22.522 51.292-32.474-7.5413-69.048-12.737-111.02-48.854-184.22-24.882-243.48 12.538-31.362 22.94-38.123 18.031-52.018 3.3925z" fill-opacity=".088785" fill-rule="evenodd" />
          <path id="path7928" d="m518.18 506.84c17.7-11.806 142.03-48.784 250.39 0.2903 23.123 3.6286 98.858 53.611-0.7054 32.055-133-50.395-171.29-4.5008-267.99-4.8516-33.942-0.036 6.0985-23.11 18.302-27.494z" fill-rule="evenodd" fill-opacity=".21978" fill="#fff"
          />
          <g id="g7930" fill-opacity=".22527" transform="matrix(4.7851 0 0 4.3378 13110 2152.4)">
              <g id="g7932" transform="matrix(-.83950 0 0 -.83950 -1927.7 -1685.1)">
                  <ellipse id="ellipse7934" rx="3.4745" ry="4.2727" cy="-1590.9" cx="1067.7" />
                  <ellipse id="ellipse7936" rx="1.759" ry="2.1631" cy="-1602.6" cx="1066" />
                  <ellipse id="ellipse7938" rx="1.759" ry="2.1631" cy="-1593" cx="1075.6" />
                  <ellipse id="ellipse7940" rx="1.759" ry="2.1631" cy="-1596" cx="1060.3" />
              </g>
              <g id="g7942" transform="matrix(-.97834 0 0 -.97834 -1770.1 -1913.4)">
                  <ellipse id="ellipse7944" rx="3.4745" ry="4.2727" cy="-1590.9" cx="1067.7" />
                  <ellipse id="ellipse7946" rx="1.759" ry="2.1631" cy="-1602.6" cx="1066" />
                  <ellipse id="ellipse7948" rx="1.759" ry="2.1631" cy="-1593" cx="1075.6" />
                  <ellipse id="ellipse7950" rx="1.759" ry="2.1631" cy="-1596" cx="1060.3" />
              </g>
          </g>
          <g id="g7952" fill-opacity=".22527" transform="matrix(4.7851 0 0 4.3378 13623 2203.3)">
              <g id="g7954" transform="matrix(-.83950 0 0 -.83950 -1927.7 -1685.1)">
                  <ellipse id="ellipse7956" rx="3.4745" ry="4.2727" cy="-1590.9" cx="1067.7" />
                  <ellipse id="ellipse7958" rx="1.759" ry="2.1631" cy="-1602.6" cx="1066" />
                  <ellipse id="ellipse7960" rx="1.759" ry="2.1631" cy="-1593" cx="1075.6" />
                  <ellipse id="ellipse7962" rx="1.759" ry="2.1631" cy="-1596" cx="1060.3" />
              </g>
              <g id="g7964" transform="matrix(-.97834 0 0 -.97834 -1770.1 -1913.4)">
                  <ellipse id="ellipse7966" rx="3.4745" ry="4.2727" cy="-1590.9" cx="1067.7" />
                  <ellipse id="ellipse7968" rx="1.759" ry="2.1631" cy="-1602.6" cx="1066" />
                  <ellipse id="ellipse7970" rx="1.759" ry="2.1631" cy="-1593" cx="1075.6" />
                  <ellipse id="ellipse7972" rx="1.759" ry="2.1631" cy="-1596" cx="1060.3" />
              </g>
          </g>
          <g id="g7974" fill-opacity=".22527" transform="matrix(4.7851 0 0 4.3378 12875 2330.5)">
              <g id="g7976" transform="matrix(-.83950 0 0 -.83950 -1927.7 -1685.1)">
                  <ellipse id="ellipse7978" rx="3.4745" ry="4.2727" cy="-1590.9" cx="1067.7" />
                  <ellipse id="ellipse7980" rx="1.759" ry="2.1631" cy="-1602.6" cx="1066" />
                  <ellipse id="ellipse7982" rx="1.759" ry="2.1631" cy="-1593" cx="1075.6" />
                  <ellipse id="ellipse7984" rx="1.759" ry="2.1631" cy="-1596" cx="1060.3" />
              </g>
              <g id="g7986" transform="matrix(-.97834 0 0 -.97834 -1770.1 -1913.4)">
                  <ellipse id="ellipse7988" rx="3.4745" ry="4.2727" cy="-1590.9" cx="1067.7" />
                  <ellipse id="ellipse7990" rx="1.759" ry="2.1631" cy="-1602.6" cx="1066" />
                  <ellipse id="ellipse7992" rx="1.759" ry="2.1631" cy="-1593" cx="1075.6" />
                  <ellipse id="ellipse7994" rx="1.759" ry="2.1631" cy="-1596" cx="1060.3" />
              </g>
          </g>
          <path id="path7996" d="m-802.63 719.83c2.4527-20.381-8.8558-602.16-17.21-650.98-3.2827-60.262 19.896-21.822 21.738 1.5917l13.215 652.33c6.0748 97.83-15.855 25.855-17.744-2.9382z" fill-rule="evenodd" fill-opacity=".11215" />
          <path id="path7998" d="m-804.18 387.49c44.39 37.992 168.56 45.389 204 5.0369" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path8000" d="m-804.18 561.49c44.39 37.992 168.56 45.389 204 5.0369" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path8002" d="m767.75 632.37c25.715-48.033 45.948-90.376 3.4693-126.94" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path8004" d="m859.18 672.4c33.617-14.493 52.194-52.229 25.469-101.94" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path8006" d="m523.99 664.53c25.71-48.03-7.05-119.37-50.53-126.94" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path8008" d="m391.27 759.44c25.72-48.04 7.95-127.38-34.53-163.95" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path8010" d="m-1.0359 711.27c60.615-25.13 98.989-102.63 69.369-182.68" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path8012" d="m-292.28 701.54c14.56-66.05-24.87-151.62-112.42-145.08" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path8014" d="m-663.72 924.62c46.56 28.214 178.71-132.84 130.67-180.83" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path8016" d="m-380.67 874.78c21.639-65.341-12.136-174.95-96.153-193.86" stroke-opacity=".19231" stroke="#000" stroke-width="4.1975" fill="none" />
          <path id="path8018" d="m-733.87 850.57c50.105 101.42 250.19 167.45 347.58 29.71 14.985-54.19-52.63-2.958-72.937 8.4941-110.29 51.789-169.59 27.78-220.13-20.012-24.644-38.143-40.612-32.83-54.508-18.192z" fill-opacity=".14486" fill-rule="evenodd" />
          <path id="path8020" d="m-594.19 727.9c20.148 53.323 85.312 68.693 117.73 0.3901 5.3668-19.407-22.849-6.3094-30.121-2.208-35.85 34.314-60.691 6.8056-64.587-6.9171-18.076-15.66-30.045-11.757-23.021 8.735z" fill-rule="evenodd" fill-opacity=".32418" fill="#fff"
          />
          <g id="g16317" transform="matrix(.20409 0 0 .20409 278.57 -592.45)">
              <path id="path16088" d="m-5209.6 3414.7c237.55 52.585 468.38 93.357 705.93-23.387-1.7791 65.605-43.203 155.2-85.841 221.51-237.85 131.49-337.81 77.852-551.51-14.834-27.784-36.727-74.829-70.361-68.578-183.29z" fill="#464646" />
              <rect id="rect16090"  transform="matrix(.98837 .15208 -.11610 .99324 0 0)" height="63.148" width="21.982" y="4048.8" x="-4102.2" fill="#e5e5e5" />
              <rect id="rect16092"  transform="matrix(.99654 .083136 -.21075 .97754 0 0)" height="61.448" width="38.511" y="3922.6" x="-3774.6" fill="#e5e5e5" />
              <path id="path16094" d="m-4649.5 3646.3c15.782 2.0662 47.229-15.957 53.073-19.085l6.8622-108.33c-20.879 3.8039-52.432 18.925-55.553 19.755-0.3487 30.249-3.3995 77.422-4.3823 107.66z" stroke="#464646" stroke-width=".99093" fill="#e5e5e5" />
              <path id="path16096" d="m-4697.9 3660.5c15.782 2.0662 42.392-9.9106 48.236-13.038l3.9599-109.3c-20.396-2.0006-48.321 12.153-51.442 12.983-2.0665 34.1 1.9493 72.445-0.7545 109.35z" stroke="#464646" stroke-width=".99093" fill="#e5e5e5" />
              <path id="path16098" d="m-4778.5 3567.8 0.285 109.95c17.438 5.217 80.489-11.02 80.361-17.022l1.1983-106.23c-3.4083-0.8507-62.853-2.6811-81.845 13.298z" stroke="#464646" stroke-width="1.3025" fill="#e5e5e5" />
              <path id="path16100" d="m-4860.6 3570.9-2.261 111.59c19.648 5.9136 55.564-0.086 83.196-2.156l1.5037-105.62c-21.374-18.436-54.091-16.912-82.439-3.8171z" stroke="#464646" stroke-width="1.4886" fill="#e5e5e5" />
              <path id="path16102" d="m-4946.2 3570.4-1.2936 103.37c21.186 13.602 59.003 13.718 85.615 6.5509l0.5363-100.78c5.3205-17.265-46.813-24.82-84.858-9.138z" stroke="#464646" stroke-width="1.4886" fill="#e5e5e5" />
              <path id="path16104" d="m-5021.7 3555.7 3.5436 113.29c19.649 5.9137 41.537 9.5876 69.169 7.5182l1.5037-105.62c-26.231-20.297-51.99-14.173-74.216-15.184z" stroke="#464646" stroke-width="1.4886" fill="#e5e5e5" />
              <path id="path16106" d="m-5075.7 3546.8 2.8733 106.21c14.573 6.9033 48.68 15 54.525 11.873l-3.2958-109.54c-21.537-8.9375-45.311-12.396-54.102-8.5425z" stroke="#464646" stroke-width=".99093" fill="#e5e5e5" />
              <rect id="rect16108"  transform="matrix(.98238 -.18688 .46694 .88429 0 0)" height="91.982" width="33.939" y="2600.3" x="-6514.2" fill="#e5e5e5" />
              <path id="path16110" d="m-5133.7 3535 3.8408 102.34c14.331 8.5963 51.825 17.419 57.669 14.291l-3.7794-104.94c-24.553-8.9109-52.992-16.154-57.73-11.687z" stroke="#464646" stroke-width=".99093" fill="#e5e5e5" />
              <ellipse id="ellipse16112" opacity="0.443"  rx="6.9433" ry="8.4409" transform="translate(0 1182.3)" cy="2481.3" cx="-4876.9" fill="#fff" />
              <ellipse id="ellipse16114" opacity="0.443"  rx="6.9433" ry="14.666" transform="translate(0 1182.3)" cy="2451.1" cx="-4793.1" fill="#fff" />
              <path id="path16116" d="m-4946.4 3653.7c25.259-0.7895 44.184 5.0186 84.222-11.166-15.326-0.8751-77.06 2.7211-84.222 11.166z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path16118" d="m-4859.9 3582.7 83.972-2.7087c-1.3935 15.179-56.551 27.076-83.972 2.7087z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path16120" d="m-4776.8 3579.2 83.761-6.5383c-0.6993 15.226-55.256 29.629-83.761 6.5383z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path16122" d="m-5018.5 3640.6c22.904 6.7343 46.097 11.656 70.991 13.084-1.2967-9.6711-47.316-21.096-70.991-13.084z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path16124" d="m-4572.2 3426.2-7.6272 110.34c15.119 1.9794 45.619-19.508 51.218-22.504l18.017-115.12c-19.366 1.6914-56.436 23.235-61.607 27.293z" stroke="#464646" stroke-width=".94932" fill="#e5e5e5" />
              <path id="path16126" d="m-4635.4 3556.9c16.479 2.1574 49.315-16.662 55.418-19.928l7.1653-113.11c-21.802 3.9719-54.748 19.761-58.007 20.627-0.3641 31.585-3.5497 80.842-4.5759 112.41z" stroke="#464646" stroke-width="1.0347" fill="#e5e5e5" />
              <path id="path16128" d="m-4685.9 3571.7c16.479 2.1574 44.265-10.348 50.367-13.614l4.1348-114.12c-21.297-2.089-50.455 12.69-53.714 13.556-2.1578 35.606 2.0354 75.645-0.7878 114.18z" stroke="#464646" stroke-width="1.0347" fill="#e5e5e5" />
              <path id="path16130" d="m-4770.1 3474.9 0.2975 114.81c18.209 5.4474 84.044-11.507 83.911-17.774l1.2513-110.92c-3.5589-0.8883-65.629-2.7995-85.46 13.886z" stroke="#464646" stroke-width="1.36" fill="#e5e5e5" />
              <path id="path16132" d="m-4855.7 3478.2-2.3608 116.52c20.516 6.1749 58.019-0.09 86.871-2.2512l1.5701-110.28c-22.318-19.25-56.48-17.659-86.08-3.9857z" stroke="#464646" stroke-width="1.5543" fill="#e5e5e5" />
              <path id="path16134" d="m-4945.1 3477.7-1.3507 107.94c22.122 14.202 61.609 14.324 89.396 6.8402l0.56-105.23c5.5555-18.028-48.88-25.916-88.606-9.5416z" stroke="#464646" stroke-width="1.5543" fill="#e5e5e5" />
              <path id="path16136" d="m-5023.9 3462.2 3.7001 118.29c20.516 6.1749 43.372 10.011 72.224 7.8503l1.5701-110.28c-27.39-21.193-54.287-14.799-77.494-15.855z" stroke="#464646" stroke-width="1.5543" fill="#e5e5e5" />
              <path id="path16138" d="m-5080.3 3453 3.0002 110.9c15.216 7.2082 50.831 15.663 56.933 12.397l-3.4413-114.38c-22.489-9.3323-47.312-12.943-56.492-8.9198z" stroke="#464646" stroke-width="1.0347" fill="#e5e5e5" />
              <path id="path16140" d="m-5140.9 3440.6 4.0104 106.86c14.964 8.976 54.114 18.188 60.216 14.923l-3.9464-109.58c-25.638-9.3045-55.333-16.867-60.28-12.203z" stroke="#464646" stroke-width="1.0347" fill="#e5e5e5" />
              <rect id="rect16142"  height="89.095" width="21.92" y="3418.6" x="-5210" fill="#e5e5e5" />
              <path id="path16144" d="m-5201.5 3427.3 7.7984 101.05c10.418 8.2184 50.073 21.219 56.175 17.953l-3.6938-105.79c-23.653-7.1398-38.673-14.079-60.28-13.213z" stroke="#464646" stroke-width="1.0347" fill="#e5e5e5" />
              <path id="path16146" d="m-4864.2 3438.2c-177.72-2.3483-270.53-59.076-347.58-28.504 1.7351 96.442 279.25 89.637 348.68 80.306 94.525 3.8198 362.71-16.34 361.4-101.12-79.409-26.78-257.12 40.202-362.51 49.317z" fill-rule="evenodd" fill="#edb079" />
              <ellipse id="ellipse16148" opacity="0.443"  rx="7.25" ry="8.8137" transform="matrix(0 .51234 -1.6017 0 -1067 6084.5)" cy="2392.6" cx="-4872.8" fill="#fff" />
              <ellipse id="ellipse16150" opacity="0.443"  rx="7.25" ry="15.314" transform="translate(0 1182.3)" cy="2361.1" cx="-4785.2" fill="#fff" />
              <ellipse id="ellipse16152" opacity=".275"  rx="7.25" transform="matrix(.33794 .94117 -.94117 .33794 0 1182.3)" ry="15.314" cy="5173.1" cx="519.47" fill="#fff" />
              <path id="path16154" d="m-4850.7 3719.7c-180.03 0.9159-424.19-101.87-357.8-311.04-6.5771 221.22 277.7 278.04 354.91 229.3 75.812 47.18 295.11 31.553 351.83-243.31-5.6005 249.86-182.17 333.07-348.94 325.05z" fill-rule="evenodd" fill="#edb079" />
              <path id="path16156" d="m-5120.9 3619.2c236.31 145.14 389.96 102.16 517.6 0-196.47 114.87-358.15 62.792-517.6 0z" fill-rule="evenodd" fill-opacity=".31707" fill="#fff" />
              <path id="path16158" d="m-5150.6 3402.8c9.2979 26.723 275.87 60.608 274.36 42.426-75.066 20.608-175.81 43.616-274.36-42.426z" fill-rule="evenodd" fill-opacity=".31707" fill="#fff" />
              <path id="path16160" d="m-4944.1 3494.7 86.806-4.7033c0.01 11.257-54.712 20.559-86.806 4.7033z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path16162" d="m-4855 3490.5 87.681-2.8283c-1.3182 10.238-58.73 15.215-87.681 2.8283z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path16164" d="m-4769.7 3487.9 84.809-8.9485c-10.862 10.38-51.725 16.639-84.809 8.9485z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path16166" d="m-5022.6 3492.6c25.733 1.7224 49.996 3.4632 75.407 2.2966-0.3499 10.263-47.726 11.033-75.407-2.2966z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path16168" d="m-5079.7 3487.3c23.435 2.9598 32.318 4.5239 56.138 6.0091-10.249 4.2523-41.362-0.8115-56.138-6.0091z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path16170" d="m-5139.5 3475c25.209 5.4486 34.464 9.6409 59.774 12.184-12.446 1.9708-39.101-2.2169-59.774-12.184z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path16172" d="m-5199.9 3440.2c17.394 19.585 36.699 26.043 59.591 34.115-12.515 2.9774-47.084-11.169-59.591-34.115z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path16174" d="m-4684.1 3479c19.583-2.9082 37.18-5.8765 51.273-8.951-7.6667 4.6477-29.182 11.091-51.273 8.951z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path16176" d="m-4631 3469.4c19.546-4.0879 37.482-9.3564 55.886-15.239-6.5633 7.691-33.797 15.889-55.886 15.239z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path16178" d="m-4573.9 3453.6c22.884-8.587 44.816-18.049 59.484-34.195-3.4946 10.806-33.848 38.506-59.484 34.195z" fill-opacity=".085366" fill-rule="evenodd" />
              <ellipse id="ellipse16180" opacity="0.443"  rx="7.25" transform="matrix(.41379 0 0 .41379 -2722.4 2542.4)" ry="15.314" cy="2361.1" cx="-4785.2" fill="#fff" />
              <ellipse id="ellipse16182" opacity="0.443"  rx="7.25" ry="15.314" transform="matrix(.41379 0 0 .41379 -2625.9 2600.9)" cy="2361.1" cx="-4785.2" fill="#fff" />
              <ellipse id="ellipse16184" opacity="0.443"  rx="7.25" transform="matrix(.41379 0 0 .41379 -3049.9 2616.9)" ry="15.314" cy="2361.1" cx="-4785.2" fill="#fff" />
              <ellipse id="ellipse16186" opacity="0.443"  rx="7.25" ry="15.314" transform="matrix(.41379 0 0 1.5912 -3166.2 -246.13)" cy="2361.1" cx="-4785.2" fill="#fff" />
              <path id="path16188" d="m-4777.4 3658.4c23.226-0.7189 37.348 1.792 79.472-10.916-13.427 4.251-70.89 6.3054-79.472 10.916z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path16190" d="m-4696.7 3646.8c14.274-2.7498 28.141-11.448 47.654-20.707-8.768-0.5007-43.557 15.876-47.654 20.707z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path16192" d="m-4648 3625.5c22.934-12.296 51.195-39.025 53.842-42.981-8.8719 1.2626-49.877 40.413-53.842 42.981z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path16194" d="m-4593.2 3581.4c13.92-10.174 27.223-31.6 34.75-42.097-5.7239 1.4019-30.34 37.46-34.75 42.097z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path16196" d="m-5073.4 3619.5c14.93 6.0535 31.031 15.737 54.116 21.084-12.547-9.1711-32.566-19.221-54.116-21.084z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path16198" d="m-5131.6 3581.6c15.529 12.984 39.347 29.406 57.991 37.834-1.2967-9.6711-50.69-38.971-57.991-37.834z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path16200" d="m-4853.5 3638.3c20.956 11.928 44.059 18.615 74.282 20.143-5.3965-1.7736-58.483-21.699-74.282-20.143z" fill-opacity=".085366" fill-rule="evenodd" />
          </g>
          <g id="g8560" fill-opacity=".22527" transform="matrix(-.83950 0 0 .83950 95.058 1359.5)">
              <ellipse id="ellipse8562" rx="3.4745" ry="4.2727" cy="-1590.9" cx="1067.7" />
              <ellipse id="ellipse8564" rx="1.759" ry="2.1631" cy="-1602.6" cx="1066" />
              <ellipse id="ellipse8566" rx="1.759" ry="2.1631" cy="-1593" cx="1075.6" />
              <ellipse id="ellipse8568" rx="1.759" ry="2.1631" cy="-1596" cx="1060.3" />
          </g>
          <g id="g6412" transform="matrix(1.1067 0 0 1.1067 73.803 -5.4635)">
              <g id="g5094" transform="matrix(-1.2088 0 0 1.2088 455.48 -4506)">
                  <path id="path5096" d="m1023.3 3774.9c0 4.7864-12.253 8.6666-27.368 8.6666s-27.368-3.8802-27.368-8.6666c0-4.7865 12.253-8.6666 27.368-8.6666s27.368 3.8801 27.368 8.6666z" fill="#fff" />
                  <path id="path5098" d="m995.94 3766.2c-1.8893 0-3.7268 0.07-5.5084 0.1851h-0.0142c-2.9307 1.638-4.9106 4.7592-4.9106 8.3551 0 3.8169 2.2352 7.1146 5.4657 8.654 1.6154 0.094 3.2662 0.1424 4.9675 0.1424 1.0337 0 2.0563-0.021 3.0602-0.057 3.3292-1.5006 5.6507-4.8506 5.6507-8.7395 0-3.6707-2.0642-6.8466-5.0956-8.4547-1.1839-0.05-2.3888-0.086-3.6153-0.086z"
                      fill="#936218" />
                  <path id="path5100" d="m999.14 3774.9c0 2.1181-1.717 3.8351-3.8351 3.8351s-3.8351-1.717-3.8351-3.8351 1.717-3.8351 3.8351-3.8351 3.8351 1.717 3.8351 3.8351z" fill="#0d0d0d" />
                  <path id="path5102" d="m994.94 3770.8c0 0.8589-0.69629 1.5552-1.5552 1.5552s-1.5552-0.6963-1.5552-1.5552 0.69629-1.5552 1.5552-1.5552c0.85892 0 1.5552 0.6963 1.5552 1.5552z" fill="#fff" />
                  <path id="path5104" opacity=".39024" d="m994.11 3781.3c6.2502-0.7396 8.0449-3.8554 7.1526-8.4043-0.8425-1.509 1.9783-4.2225 2.3246 1.8776-0.4529 5.9045-3.7625 7.5881-7.5102 8.0467-4.1285 0.3399-5.6845-1.4109-1.967-1.52z" fill="#f0f2fd" />
                  <path id="leftEyeLid4" d="m1023.3 3774.9c0 0.5842-12.253-6.8903-27.368-6.8903s-27.215 7.4612-27.368 6.8903c-1.2388-4.6234 12.253-8.6667 27.368-8.6667s27.368 3.8802 27.368 8.6667z" class="EyeBlink" fill="#d4d4d4" />
              </g>
          </g>
          <g id="g6421" transform="matrix(1.0534 0 0 1.0534 113.42 -1.9741)">
              <g id="g6423" transform="matrix(-1.2088 0 0 1.2088 455.48 -4506)">
                  <path id="path6425" fill="#fff" d="m1023.3 3774.9c0 4.7864-12.253 8.6666-27.368 8.6666s-27.368-3.8802-27.368-8.6666c0-4.7865 12.253-8.6666 27.368-8.6666s27.368 3.8801 27.368 8.6666z" />
                  <path id="path6427" fill="#936218" d="m995.94 3766.2c-1.8893 0-3.7268 0.07-5.5084 0.1851h-0.0142c-2.9307 1.638-4.9106 4.7592-4.9106 8.3551 0 3.8169 2.2352 7.1146 5.4657 8.654 1.6154 0.094 3.2662 0.1424 4.9675 0.1424 1.0337 0 2.0563-0.021 3.0602-0.057 3.3292-1.5006 5.6507-4.8506 5.6507-8.7395 0-3.6707-2.0642-6.8466-5.0956-8.4547-1.1839-0.05-2.3888-0.086-3.6153-0.086z"
                  />
                  <path id="path6429" d="m999.14 3774.9c0 2.1181-1.717 3.8351-3.8351 3.8351s-3.8351-1.717-3.8351-3.8351 1.717-3.8351 3.8351-3.8351 3.8351 1.717 3.8351 3.8351z" fill="#0d0d0d" />
                  <path id="path6431" d="m994.94 3770.8c0 0.8589-0.69629 1.5552-1.5552 1.5552s-1.5552-0.6963-1.5552-1.5552 0.69629-1.5552 1.5552-1.5552c0.85892 0 1.5552 0.6963 1.5552 1.5552z" fill="#fff" />
                  <path id="path6433" opacity=".39024" d="m994.11 3781.3c6.2502-0.7396 8.0449-3.8554 7.1526-8.4043-0.8425-1.509 1.9783-4.2225 2.3246 1.8776-0.4529 5.9045-3.7625 7.5881-7.5102 8.0467-4.1285 0.3399-5.6845-1.4109-1.967-1.52z" fill="#f0f2fd" />
                  <path id="rightEyeLid4" d="m1023.3 3774.9c0 0.5842-12.253-6.8903-27.368-6.8903s-27.215 7.4612-27.368 6.8903c-1.2388-4.6234 12.253-8.6667 27.368-8.6667s27.368 3.8802 27.368 8.6667z" class="EyeBlink" fill="#d4d4d4" />
              </g>
          </g>
      </g>
      <use id="use6457" xlinkHref="#sunflower" transform="matrix(.48766 0 0 .48766 768.71 195.28)" height="100%" width="100%" y="0" x="0" />

    {/* Snail */}
      <g id="snail">
          <ellipse id="ellipse28194" fill-opacity=".071429" rx="343.03" ry="43.914" cy="171.89" cx="-667.55" />
          <path id="path28196" d="m-917.78-189.61c-8.5031 7.5293-13.771 154.53-2.1989 189.01 25.532 48.922 51.666 41.062 82.625 13.846-0.7893 62.531 268.04 90.555 297.63 64.448 49.226-17.228 90.645-5.8305 133.58 0 85.872 9.6901 107.3 71.672 35.153 78.509 0 0-590.96 28.054-618.11-8.2024-86.026-95.488-61.055-209.3-88.469-330.44 13.318 5.452 18.319-0.75516 25.779-3.5151 6.6408 35.231 5.8184 74.94 22.264 104.29 24.424 25.365 47.715 27.437 69.964-1.9761 12.86-27.732 10.835-68.721 10.889-96.453 14.413 13.473 35.801-14.2 30.893-9.5164z"
              fill-rule="evenodd" fill="#dfc988" />
          <path id="path28198" d="m-844.04 21.425c127.68-425.04 575.39 38.497 280.89 68.771-244.99 5.736-258.52-55.79-280.89-68.771z" fill-rule="evenodd" fill="#e9e9e9" />
          <path id="path28200" d="m-888.19-855.17c-9.7422 11.461-20.256-7.4776-19.05-16.192 3.2698-23.616 32.681-29.754 51.434-21.907 33.544 14.037 40.821 56.961 24.765 86.676-23.563 43.608-81.558 52.182-121.92 27.622-53.794-32.735-63.667-106.27-30.48-157.16 41.764-64.045 131.03-75.216 192.4-33.337 74.333 50.722 86.802 155.82 36.195 227.64-59.64 84.645-180.63 98.411-262.88 39.052-94.972-68.533-110.04-205.45-41.91-298.13 77.409-105.31 230.28-121.67 333.37-44.767"
              stroke-opacity=".32710" transform="matrix(-.47427 0 0 -.23164 -1076.1 -232.41)" stroke="#000" stroke-width="2.6248" fill="none" />
          <path id="path28204" d="m-920.15 33.8c5.133 3.4235 41.188 14.147 72.615-0.0841 1.7792 0.20938 13.111-5.6637 0.41061-3.5542-37.749 18.51-50.288-4.4367-78.333-4.3349-9.8433 0.0116 1.7686 6.7019 5.3074 7.9733z" fill-opacity=".20879" fill-rule="evenodd" fill="#fff"
          />
          <path id="path28272" d="m-1025.6-64.629c5.133 3.4235 41.188 14.147 72.615-0.08439 1.7792 0.20938 13.111-5.6634 0.41062-3.5542-39.208 18.724-77.248-0.49097-78.333-4.3346-9.8432 0.0116 1.7686 6.7016 5.3075 7.9733z" fill-rule="evenodd" fill-opacity=".20879"
              fill="#fff" />
          <path id="path28274" d="m-521.4 79.656c-43.466 24.209-268.12 9.9114-303.84-42.342 1.6894 58.473 264.68 91.073 303.84 42.342z" fill-opacity=".14286" fill-rule="evenodd" />
          <g id="g25172" transform="matrix(0.29 0 0 0.29 -3.0367 -3362.2)">
              <ellipse id="ellipse25042" rx="140.82" ry="74.376" cy="10855" cx="-3657.8" fill="#fff" />
              <g id="g28546" transform="translate(384.29 -12.857)">
                  <g id="g28554">
                      <ellipse id="circle28330" rx="49.288" ry="87.002" cy="10860" cx="-4050.8" fill="#b6fbaa" />
                      <ellipse id="circle28332" rx="19.734" ry="34.834" cy="10861" cx="-4049.7" fill="#2a2a2a" />
                      <ellipse id="circle28334" rx="8.0025" ry="14.126" cy="10824" cx="-4059.6" fill="#fff" />
                      <path id="path28336" opacity=".39024" d="m-4055.9 10919c32.161-6.717 41.396-35.018 36.805-76.335-4.3355-13.706 10.179-38.354 11.961 17.053-2.3303 53.631-19.36 68.922-38.645 73.088-21.244 3.087-29.25-12.815-10.121-13.806z" fill="#f0f2fd" />
                  </g>
                  <path id="ellipse28324" d="m-4046.8 10734a147.85 128.17 0 0 0 -147.85 128.17 147.85 128.17 0 0 0 147.85 128.17 147.85 128.17 0 0 0 147.85 -128.17 147.85 128.17 0 0 0 -147.85 -128.17zm-1 45.02a140.82 78.718 0 0 1 140.82 78.719 140.82 78.718 0 0 1 -140.82 78.718 140.82 78.718 0 0 1 -140.82 -78.718 140.82 78.718 0 0 1 140.82 -78.719z"
                      fill="#dabf73" />
                  <path id="leftEyeLidsnail" d="m-3905.6 10857c0 5.307-63.049-62.584-140.82-62.584-77.774 0-140.04 67.77-140.82 62.584-6.3745-41.994 63.049-78.719 140.82-78.719 77.774 0 140.82 35.244 140.82 78.719z" fill="#d4d4d4" />
              </g>
          </g>
          <g id="g28360" transform="matrix(.39532 0 0 .39532 356.2 -2417.3)">
              <g id="g28362" transform="matrix(.36417 0 0 .36417 -1632.5 5339.4)">
                  <g id="g28364" transform="translate(0,474)">
                      <path id="path28366" d="m-5209.6 1328.4c237.55 52.585 468.38 93.357 705.93-23.387-1.7791 65.605-43.203 155.2-85.841 221.51-237.85 131.49-337.81 77.852-551.51-14.834-27.784-36.727-74.829-70.361-68.578-183.29z" fill="#858585" />
                      <rect id="rect28368" transform="matrix(.98837 .15208 -.11610 .99324 0 0)" height="63.148" width="21.982" y="1985.5"
                          x="-4344.6" fill="#fdfdfd" />
                      <path id="path28370" d="m-4572.2 1340-7.6272 110.34c15.119 1.9794 45.619-19.508 51.218-22.504l18.017-115.12c-19.366 1.6914-56.436 23.235-61.607 27.293z" stroke="#464646" stroke-width=".94932" fill="#fff" />
                      <path id="path28372" d="m-4635.4 1470.6c16.479 2.1574 49.315-16.662 55.418-19.928l7.1653-113.11c-21.802 3.9719-54.748 19.761-58.007 20.627-0.3641 31.585-3.5497 80.842-4.5759 112.41z" stroke="#464646" stroke-width="1.0347" fill="#fff" />
                      <path id="path28374" d="m-4685.9 1485.5c16.479 2.1574 44.265-10.348 50.367-13.614l4.1348-114.12c-21.297-2.089-50.455 12.69-53.714 13.556-2.1578 35.606 2.0354 75.645-0.7878 114.18z" stroke="#464646" stroke-width="1.0347" fill="#fff" />
                      <path id="path28376" d="m-4770.1 1388.7 0.2975 114.81c18.209 5.4474 84.044-11.507 83.911-17.774l1.2513-110.92c-3.5589-0.8883-65.629-2.7995-85.46 13.886z" stroke="#464646" stroke-width="1.36" fill="#fff" />
                      <path id="path28378" d="m-4855.7 1392-2.3608 116.52c20.516 6.1749 58.019-0.09 86.871-2.2512l1.5701-110.28c-22.318-19.25-56.48-17.659-86.08-3.9857z" stroke="#464646" stroke-width="1.5543" fill="#fff" />
                      <path id="path28380" d="m-4945.1 1391.5-1.3507 107.94c22.122 14.202 61.609 14.324 89.396 6.8402l0.56-105.23c5.5555-18.028-48.88-25.916-88.606-9.5416z" stroke="#464646" stroke-width="1.5543" fill="#fff" />
                      <path id="path28382" d="m-5023.9 1376 3.7001 118.29c20.516 6.1749 43.372 10.011 72.224 7.8503l1.5701-110.28c-27.39-21.193-54.287-14.799-77.494-15.855z" stroke="#464646" stroke-width="1.5543" fill="#fff" />
                      <path id="path28384" d="m-5080.3 1366.8 3.0002 110.9c15.216 7.2082 50.831 15.663 56.933 12.397l-3.4413-114.38c-22.489-9.3323-47.312-12.943-56.492-8.9198z" stroke="#464646" stroke-width="1.0347" fill="#fff" />
                      <path id="path28386" d="m-5140.9 1354.4 4.0104 106.86c14.964 8.976 54.114 18.188 60.216 14.923l-3.9464-109.58c-25.638-9.3045-55.333-16.867-60.28-12.203z" stroke="#464646" stroke-width="1.0347" fill="#fff" />
                      <rect id="rect28388"  height="89.095" width="21.92" y="1332.4" x="-5210" fill="#fdfdfd" />
                      <path id="path28390" d="m-5201.5 1341.1 7.7984 101.05c10.418 8.2184 50.073 21.219 56.175 17.953l-3.6938-105.79c-23.653-7.1397-38.673-14.079-60.28-13.213z" stroke="#464646" stroke-width="1.0347" fill="#fff" />
                      <path id="path28392" d="m-4864.2 1352c-177.72-2.3483-270.53-59.076-347.58-28.504 1.7351 96.442 279.25 89.637 348.68 80.306 94.525 3.8198 362.71-16.34 361.4-101.12-79.409-26.78-257.12 40.202-362.51 49.317z" fill-rule="evenodd" fill="#e8d8a8" />
                      <path id="path28394" d="m-4850.7 1633.5c-180.03 0.9159-424.19-101.87-357.8-311.04-6.5771 221.22 277.7 278.04 354.91 229.3 75.812 47.18 295.11 31.553 351.83-243.31-5.6005 249.86-182.17 333.07-348.94 325.05z" fill-rule="evenodd" fill="#e8d9ab" />
                  </g>
                  <ellipse id="ellipse28396" opacity="0.443"  rx="7.25" ry="8.8137" cy="1962.7" cx="-4872.8" fill="#9d9d9d" />
                  <ellipse id="ellipse28398" opacity="0.443"  rx="7.25" ry="15.314" cy="1931.2" cx="-4785.2" fill="#e2e2e2" />
                  <ellipse id="ellipse28400" opacity=".275"  rx="7.25" transform="rotate(70.249)" ry="15.314" cy="5027.9" cx="114.84"
                      fill="#fff" />
                  <ellipse id="ellipse28402" opacity="0.443"  rx="7.25" ry="15.314" cy="1931.2" cx="-5064" fill="#e2e2e2" />
                  <ellipse id="ellipse28404" opacity="0.443"  rx="7.25" ry="15.314" cy="1891.5" cx="-4562.4" fill="#e2e2e2" />
              </g>
              <path id="path28406" d="m-3487.6 6070.2c79.074 48.567 130.49 34.185 173.2 0-65.742 38.44-119.85 21.012-173.2 0z" fill-rule="evenodd" fill-opacity=".31707" fill="#fff" />
              <path id="path28408" d="m-3515.7 5989.7c3.1113 8.9421 92.314 20.281 91.807 14.197-25.119 6.896-58.832 14.595-91.807-14.197z" fill-rule="evenodd" fill-opacity=".31707" fill="#fff" />
              <path id="path28410" d="m-3432.5 6025.5 29.34-0.9464c-0.132 5.6189-18.899 10.347-29.34 0.9464z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path28412" d="m-3400.2 6024.1 29.34-0.9464c-0.4869 5.3034-19.759 9.4606-29.34 0.9464z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path28414" d="m-3368.1 6022.4 29.267-2.2845c-0.2444 5.3201-19.307 10.352-29.267 2.2845z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path28416" d="m-3460.8 6025.8 26.028-0.9464c-0.1171 5.619-16.765 10.347-26.028 0.9464z" fill-opacity=".085366" fill-rule="evenodd" />
          </g>
          <use id="use6318" xlinkHref="#g25172" transform="translate(128.29 5.0508)" height="100%" width="100%" y="0" x="0" />
          <g id="g4324" transform="matrix(.73676 0 0 .73676 -126.45 19.078)">
              <ellipse id="ellipse28290" fill-opacity=".22527" rx="9.5217" ry="10.513" transform="scale(-1)" cy="-82.987" cx="456.72" />
              <use id="use4314" xlinkHref="#ellipse28290" transform="matrix(.49893 0 0 .49893 -247.73 50.422)" height="100%" width="100%" y="0" x="0" />
              <use id="use4316" xlinkHref="#ellipse28290" transform="matrix(.66878 0 0 .66878 -135.14 40.255)" height="100%" width="100%" y="0" x="0" />
              <use id="use4318" xlinkHref="#ellipse28290" transform="matrix(.76220 0 0 .76220 -116.94 43.127)" height="100%" width="100%" y="0" x="0" />
              <use id="use4320" xlinkHref="#ellipse28290" transform="matrix(.31209 0 0 .31209 -307.88 84.499)" height="100%" width="100%" y="0" x="0" />
              <use id="use4322" xlinkHref="#ellipse28290" transform="matrix(.33757 0 0 .33757 -320.28 48.724)" height="100%" width="100%" y="0" x="0" />
          </g>
          <use id="use4332" xlinkHref="#g4324" transform="matrix(.38658 0 0 .38658 -498.59 -179.47)" height="100%" width="100%" y="0" x="0" />
          <use id="use4334" xlinkHref="#g4324" transform="matrix(.65921 0 0 .65921 -710.84 3.9843)" height="100%" width="100%" y="0" x="0" />
          <use id="use4336" xlinkHref="#g4324" transform="matrix(.76145 0 0 .76145 -456.02 -79.14)" height="100%" width="100%" y="0" x="0" />
          <use id="use4338" xlinkHref="#g4324" transform="matrix(.79553 0 0 .79553 -628.93 -32.324)" height="100%" width="100%" y="0" x="0" />
          <use id="use4340" xlinkHref="#g4324" transform="matrix(.40362 0 0 .40362 -220.76 49.651)" height="100%" width="100%" y="0" x="0" />
          <use id="use4342" xlinkHref="#g4324" transform="matrix(.53993 0 0 .53993 -555.28 -89.514)" height="100%" width="100%" y="0" x="0" />
          <use id="use4344" xlinkHref="#g4324" transform="matrix(.23996 0 0 .27582 -549.37 -175.02)" height="100%" width="100%" y="0" x="0" />
          <use id="use4346" xlinkHref="#g4324" transform="matrix(.56057 0 0 .56057 -172.51 36.133)" height="100%" width="100%" y="0" x="0" />
          <path id="path4350" d="m-948.64-174.9c14.676 6.0762 19.555 2.8605 27.432-1.1364-8.1326 2.8983-17.533 3.5437-27.432 1.1364z" fill-rule="evenodd" fill-opacity=".093407" />
          <path id="path4352" d="m-1077.4-179.99c14.676 6.0762 19.555 2.8605 27.432-1.1364-8.1326 2.8983-17.533 3.5437-27.432 1.1364z" fill-opacity=".11538" fill-rule="evenodd" />
          <path id="path4354" d="m-1029.2-15.84c28.136 23.37 67.598 20.516 78.95 0.25253-19.412 19.43-59.434 14.644-78.95-0.25253z" fill-rule="evenodd" fill-opacity=".11538" />
          <path id="path4356" d="m-357.79 153.86c-38.412 5.141-594.23 4.5241-629.96-4.5472 7.5626 11.321 97.295 18.611 206.98 17.529 177.14-1.748 398.05-7.958 422.98-12.982z" fill-rule="evenodd" fill-opacity=".14286" />
          <path id="path4358" d="m-754.52-118.95c37.763-48.676 175.41-28.915 183.84-1.9446-36.35-29.114-176.19-17.13-183.84 1.9446z" fill-rule="evenodd" fill-opacity=".42857" fill="#fff" />
          <ellipse id="path4364"  fill-opacity=".19780" rx="13.835" ry="3.4588" cy="-242.44" cx="-937.35" fill="#fff" />
          <ellipse id="ellipse4366"  fill-opacity=".19780" rx="13.835" ry="3.4588" cy="-246.23" cx="-1066.1" fill="#fff"
          />
          <use id="use4368" xlinkHref="#g4324" transform="matrix(.32204 0 0 .32204 -793.2 -165.53)" height="100%" width="100%" y="0" x="0" />
          <path id="path4370" d="m-513.01 86.884c5.133-3.4235 42.617-21.29 74.043-7.0588 1.7792-0.20938 11.682 12.807-1.018 10.697-37.749-18.51-50.288 4.4367-78.333 4.3349-9.8433-0.0116 1.7686-6.7019 5.3074-7.9733z" fill-rule="evenodd" fill-opacity=".20879" fill="#fff"
          />
          <path id="path4372" d="m-411.32 81.064c6.1421-0.58531 47.618 1.4234 68.561 28.837 1.6662 0.6579 4.2266 16.811-5.9611 8.9404-24.487-34.176-46.396-19.901-71.05-33.267-8.6647-4.6704 4.7307-5.0659 8.4498-4.5103z" fill-opacity=".20879" fill-rule="evenodd"
              fill="#fff" />
          <path id="path4374" d="m-511.06-63.97c64.113 57.388 36.697 146.08-86.974 146.11 112.58-39.955 102.23-104.23 86.974-146.11z" fill-opacity=".42857" fill-rule="evenodd" fill="#fff" />
          <use id="use4376" xlinkHref="#g4324" transform="matrix(.29270 0 0 .29270 -952.47 -218.06)" height="100%" width="100%" y="0" x="0" />
          <use id="use4378" xlinkHref="#g4324" transform="matrix(.32930 0 0 .32930 -768.61 -212.53)" height="100%" width="100%" y="0" x="0" />
      </g>
      <use id="use4365" xlinkHref="#g11495" transform="matrix(1.3616 0 0 1.3616 769.34 -279.1)" height="100%" width="100%" y="0" x="0" />
      <g id="g11495" fill-rule="evenodd" transform="matrix(4.1249 0 0 4.1249 1752 -4648.6)">
          <path id="path11493" d="m-405.32 1309.4c-13.414 12.414-14.279 39.015-13.083 39.377 4.706-11.917 7.081-24.629 18.311-34.321-8.9921 11.914-11.553 27.076-16.136 41.217 7.5167-11.953 15.717-23.34 21.713-36.551-3.6978 8.5639-8.4412 16.928-7.0501 26.462 3.0805-9.7778 7.6053-16.286 13.049-20.712 1.9493-4.0742-13.385 36.581-12.698 35.844 1.3876-1.4873 22.145-48.2 24.443-46.673-6.7837-1.8211-7.6092 4.1466-28.55-4.6428z"
              fill-opacity=".085271" fill="#464646" />
          <path id="path11463" d="m-407.81 1314.3c-2.4216-18.116 13.87-39.162 15.022-38.679-3.9712 12.182-10.245 23.49-7.7717 38.116 0.66667-14.911 8.3594-28.226 13.844-42.042-1.8286 14.001-2.7696 28.002-6.5714 42.003 2.6103-8.9555 4.288-18.424 11.438-24.883-3.8598 9.4972-4.5222 17.396-3.1499 24.277-1.0954 4.3817 13.007-36.717 13.067-35.711 0.12091 2.0305-6.3991 36.869-3.655 37.158-7.9953 1.5427-10.485 6.3385-32.222-0.2389z"
              fill="#1a7c1a" />
          <path id="path11465" d="m-406.95 1313c-2.7609-10.933 8.2078-30.691 10.985-33.272-5.8239 10.94-9.9203 21.856-10.985 33.272z" fill-opacity=".12150" />
          <path id="path11467" d="m-400.01 1313.8c1.2994-13.519 6.497-24.61 10.48-34.913-3.5462 11.532-7.5327 22.184-10.48 34.913z" fill-opacity=".12150" />
          <path id="path11469" d="m-391.55 1313c-1.2031-4.5606 5.7222-21.554 6.7554-20.14-3.4681 6.3658-5.6438 13.101-6.7554 20.14z" fill-opacity=".12150" />
          <path id="path11471" d="m-382.96 1312.4c-0.67224-1.9323 8.9692-29.526 9.4702-29.168z" fill-opacity=".12150" />
          <path id="path11473" d="m-405.62 1313.4c10.082 2.2332 19.528 1.9232 28.661 0.3572-11.261 4.8474-19.955 2.2288-28.661-0.3572z" fill-opacity=".12150" />
          <path id="path11475" d="m-389.11 1287.7c-1.6781 5.8226-3.3272 11.642-2.6786 17.232z" fill-opacity=".12150" fill="#fff" />
          <path id="path11477" d="m-386.16 1310.3c-2.148-4.494 0.15892-8.9881 0.80357-13.482-0.55951 4.5524-1.3757 9.1561-0.80357 13.482z" fill-opacity=".12150" fill="#fff" />
          <path id="path11479" d="m-402.5 1309.9c-2.2982-12.366 3.1121-18.257 6.3393-25.982-3.3744 6.8738-5.8141 15.072-6.3393 25.982z" fill-opacity=".12150" fill="#fff" />
          <path id="path11481" d="m-377.5 1309.4c0.51176-1.298 2.1967-13.91 2.3214-13.661-1.9807 4.2518-2.2107 8.9414-2.3214 13.661z" fill-opacity=".12150" fill="#fff" />
      </g>
      <use id="use5499" xlinkHref="#g11495" transform="matrix(.24351 0 0 .24351 1131.4 257.72)" height="100%" width="100%" y="0" x="0" />
     
     {/* Sun  */}
      <g id="sunG" class="sunSVG">
          <path id="path10707" d="m-486.8 287c18.174 9.4943 21.502 20.639 21.695 32.131-2.0224 1.1409-3.9853 2.3727-5.8848 3.6914-18.786-16.456-30.556-14.981-39.738-6.8945 16.107-3.2316 20.603 8.464 22.808 23.105-2.4789 3.2627-4.6894 6.7385-6.6113 10.391-26.912-4.5025-25.173 5.5024-46.597 11.192 13.089 1.8589 28.452-0.8238 40.261 5.417-1.4368 5.7778-2.211 11.817-2.211 18.039 0 5.1785 0.5298 10.232 1.5352 15.113-36.702 26.176-19.26 41.349-19.186 41.387 8.6196-17.164 16.814-23.248 24.824-24.578 2.3608 4.9722 5.2639 9.6335 8.6191 13.926-8.9969 15.443-6.5575 32.904-4.5156 50.295 4.1887-13.703 7.1603-28.342 18.582-36.48 8.0293 6.0213 17.32 10.44 27.396 12.832-0.1603 12.713-5.412 32.842-1.334 41.148 4.3331-13.705 8.9318-27.144 14.906-39.207 1.2262 0.06 2.4601 0.094 3.7011 0.094 9.1686 0 17.946-1.6648 26.059-4.6934 8.2685 10.774 17.186 25.493 28.191 29.52-5.9131-11.582-14-22.874-16.932-34.854 4.4502-2.5804 8.6124-5.5982 12.418-9.0117 19.74 9.5096 59.264 5.3919 45.584 6.0098-4.6734 0.2111-26.417-7.7402-33.56-19.773 2.5443-3.7586 4.7407-7.7695 6.5742-11.975 13.722 8.453 25.237-15.076 37.344-30.039-10.618 3.6598-20.806 15.934-31.875 10.574 0.4648-3.3645 0.7246-6.7948 0.7246-10.287 0-2.5434-0.1302-5.0554-0.3789-7.5332l23.314-26.682-26.848 10.392c-1.4001-4.1512-3.1416-8.144-5.2168-11.932 1.5-18.613 3.683-35.69 13.494-35.604-7.6624-10.728-22.145 5.1432-25.81 19.06-3.0137-3.0586-6.2951-5.8497-9.7969-8.3535-1.6985-13.378-0.3314-29.199 7.3926-36.064-13.441 4.344-25.072 11.178-31.936 24.566-1.6327-0.423-3.2926-0.7749-4.9668-1.0879l-2.3828-21.693-11.869 20.414c-4.3915 0.028-8.6891 0.447-12.869 1.2031-13.471-15.907-28.577-23.097-44.906-23.76z"
              fill="#f6ed11" />
          <use id="use9173" xlinkHref="#g9158" transform="translate(80.433 43.815)" height="100%" width="100%" y="0" x="0" />
          <use id="use9171" xlinkHref="#g9158" transform="translate(63.513 96.722)" height="100%" width="100%" y="0" x="0" />
          <path id="path10709" d="m-399.79 323.77c37.685 42.689 28.788 83.515 0.6608 123.57 46.638-43.458 61.51-85.379-0.6608-123.57z" fill-opacity=".18878" fill-rule="evenodd" fill="#fff" />
          <g id="g10741" transform="translate(-1455 -3419)">
              <path id="path10743" d="m1023.3 3774.9c0 4.7864-12.253 8.6666-27.368 8.6666s-27.368-3.8802-27.368-8.6666c0-4.7865 12.253-8.6666 27.368-8.6666s27.368 3.8801 27.368 8.6666z" fill="#fff" />
              <path id="path10745" d="m995.94 3766.2c-1.8893 0-3.7268 0.07-5.5084 0.1851h-0.0142c-2.9307 1.638-4.9106 4.7592-4.9106 8.3551 0 3.8169 2.2352 7.1146 5.4657 8.654 1.6154 0.094 3.2662 0.1424 4.9675 0.1424 1.0337 0 2.0563-0.021 3.0602-0.057 3.3292-1.5006 5.6507-4.8506 5.6507-8.7395 0-3.6707-2.0642-6.8466-5.0956-8.4547-1.1839-0.05-2.3888-0.086-3.6153-0.086z"
                  fill="#936218" />
              <path id="path10747" d="m999.14 3774.9c0 2.1181-1.717 3.8351-3.8351 3.8351s-3.8351-1.717-3.8351-3.8351 1.717-3.8351 3.8351-3.8351 3.8351 1.717 3.8351 3.8351z" fill="#0d0d0d" />
              <path id="path10749" d="m994.94 3770.8c0 0.8589-0.69629 1.5552-1.5552 1.5552s-1.5552-0.6963-1.5552-1.5552 0.69629-1.5552 1.5552-1.5552c0.85892 0 1.5552 0.6963 1.5552 1.5552z" fill="#fff" />
              <path id="path10751" opacity=".39024" d="m994.11 3781.3c6.2502-0.7396 8.0449-3.8554 7.1526-8.4043-0.8425-1.509 1.9783-4.2225 2.3246 1.8776-0.4529 5.9045-3.7625 7.5881-7.5102 8.0467-4.1285 0.3399-5.6845-1.4109-1.967-1.52z" fill="#f0f2fd" />
              <path id="leftEyeLid5" d="m1023.3 3774.9c0 0.5842-12.253-6.8903-27.368-6.8903s-27.215 7.4612-27.368 6.8903c-1.2388-4.6234 12.253-8.6667 27.368-8.6667s27.368 3.8802 27.368 8.6667z" fill="#d4d4d4" />
          </g>
          <g id="g10755" transform="matrix(.13157 0 0 .13157 208.22 -131.27)">
              <path id="path10757"  d="m-5210 3970.6h21.92v89.095c-2.7702 8.7529-5.7686 16.707-17.67-6.5z" fill="#e5e5e5" />
              <path id="path10759" d="m-5207.7 3966.7c237.55 52.585 466.51 93.357 704.06-23.387-1.7791 65.605-16.333 177.12-58.971 243.43-188.59 221.07-351.5 185.11-598.18-1.3995-27.784-36.727-45.037-110.4-46.905-218.64z" fill="#464646" />
              <path id="path10761"  d="m-4038.1 4594.8h21.982c2.4687 37.461 3.52 74.743-3.485 111l-13.159 6.2002z" transform="matrix(.98837 .15208 -.11610 .99324 0 0)" fill="#e5e5e5" />
              <path id="path10763"  d="m-5661.8 2843.2h37.661l-17.609 24.336-29.184 18.272z" transform="matrix(.96605 -.25837 .30647 .95188 0 0)" fill="#e5e5e5" />
              <path id="path10765" d="m-4649.6 4269.3c15.809 1.6613 47.309-12.83 53.163-15.345l6.8738-87.1c-20.915 3.0585-52.521 15.216-55.648 15.884-0.3493 24.321-3.4052 62.25-4.3897 86.562z" stroke="#464646" stroke-width=".88930" fill="#e5e5e5" />
              <path id="path10767" d="m-4697.9 4308.5c15.782 2.0662 42.392-9.9106 48.236-13.038l3.9599-109.3c-20.396-2.0006-48.321 12.153-51.442 12.983-2.0665 34.1 1.9493 72.445-0.7545 109.35z" stroke="#464646" stroke-width=".99093" fill="#e5e5e5" />
              <path id="path10769" d="m-4778.5 4213.8 0.285 109.95c17.438 5.217 80.489-11.02 80.361-17.022l1.1983-106.23c-3.4083-0.8507-62.853-2.6811-81.845 13.298z" stroke="#464646" stroke-width="1.3025" fill="#e5e5e5" />
              <path id="path10771" d="m-4860.6 4210.9-2.261 111.59c19.648 5.9136 55.564-0.086 83.196-2.156l1.5037-105.62c-20.867-13.371-53.28-8.7969-82.439-3.8171z" stroke="#464646" stroke-width="1.4886" fill="#e5e5e5" />
              <path id="path10773" d="m-4946.2 4208.4-1.2936 103.37c21.186 13.602 59.003 13.718 85.615 6.5509l0.5363-100.78c5.9599-14.644-44.352-14.729-84.858-9.138z" stroke="#464646" stroke-width="1.4886" fill="#e5e5e5" />
              <path id="path10775" d="m-5021.7 4195.7 3.5436 113.29c19.649 5.9137 41.537 9.5876 69.169 7.5182l1.5037-105.62c-26.231-20.297-51.99-14.173-74.216-15.184z" stroke="#464646" stroke-width="1.4886" fill="#e5e5e5" />
              <path id="path10777" d="m-5075.7 4186.8 2.8733 106.21c14.573 6.9033 48.68 15 54.525 11.873l-3.2958-109.54c-21.537-8.9375-45.311-12.396-54.102-8.5425z" stroke="#464646" stroke-width=".99093" fill="#e5e5e5" />
              <rect id="rect10779"  transform="matrix(.99302 .11791 .10740 .99422 0 0)" height="43.506" width="33.14" y="4868.8" x="-5724.7" fill="#e5e5e5" />
              <path id="path10781" d="m-5133.7 4169 3.8408 70.338c14.331 8.5963 51.825 49.419 57.669 46.291l-3.7794-104.94c-24.553-8.9109-52.992-16.154-57.73-11.687z" stroke="#464646" stroke-width=".99093" fill="#e5e5e5" />
              <ellipse id="ellipse10783" opacity="0.443"  rx="6.9433" ry="8.4409" transform="translate(0 1768.3)" cy="2481.3" cx="-4876.9" fill="#fff" />
              <ellipse id="ellipse10785" opacity="0.443"  rx="6.9433" ry="14.666" transform="translate(0 1796.3)" cy="2451.1" cx="-4793.1" fill="#fff" />
              <path id="path10787" d="m-4945.4 4209c4.6805 4.6559 45.372 18.852 83.597 4.4586-6.5618-11.608-69.318-6.8812-83.597-4.4586z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path10789" d="m-4859.7 4211.5c26.159-5.3019 64.119-8.1589 80.91 3.4788 2.4665 4.5635-50.019 11.349-80.91-3.4788z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path10791" d="m-4777.9 4214c21.993-14.777 69.214-13.755 80.636-12.663-7.4221 7.7766-69.092 16.959-80.636 12.663z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path10793" d="m-5018.5 4291c20.085 8.8328 42.202 17.13 69.224 16.973-5.848-7.6308-66.147-15.751-69.224-16.973z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path10795" d="m-4572.2 3978.2-7.6272 110.34c15.119 1.9794 45.619-19.508 51.218-22.504l18.017-115.12c-19.366 1.6914-56.436 23.235-61.607 27.293z" stroke="#464646" stroke-width=".94932" fill="#e5e5e5" />
              <path id="path10797" d="m-4635.4 4108.9c16.479 2.1574 49.315-16.662 55.418-19.928l7.1653-113.11c-21.802 3.9719-54.748 19.761-58.007 20.627-0.3641 31.585-3.5497 80.842-4.5759 112.41z" stroke="#464646" stroke-width="1.0347" fill="#e5e5e5" />
              <path id="path10799" d="m-4685.9 4123.7c16.479 2.1574 44.265-10.348 50.367-13.614l4.1348-114.12c-21.297-2.089-50.455 12.69-53.714 13.556-2.1578 35.606 2.0354 75.645-0.7878 114.18z" stroke="#464646" stroke-width="1.0347" fill="#e5e5e5" />
              <path id="path10801" d="m-4770.1 4026.9 0.2975 114.81c18.209 5.4474 84.044-11.507 83.911-17.774l1.2513-110.92c-3.5589-0.8883-65.629-2.7995-85.46 13.886z" stroke="#464646" stroke-width="1.36" fill="#e5e5e5" />
              <path id="path10803" d="m-4855.7 4030.2-2.3608 116.52c20.516 6.1749 58.019-0.09 86.871-2.2512l1.5701-110.28c-22.318-19.25-56.48-17.659-86.08-3.9857z" stroke="#464646" stroke-width="1.5543" fill="#e5e5e5" />
              <path id="path10805" d="m-4945.1 4029.7-1.3507 107.94c22.122 14.202 61.609 14.324 89.396 6.8402l0.56-105.23c5.5555-18.028-48.88-25.916-88.606-9.5416z" stroke="#464646" stroke-width="1.5543" fill="#e5e5e5" />
              <path id="path10807" d="m-5023.9 4014.2 3.7001 118.29c20.516 6.1749 43.372 10.011 72.224 7.8503l1.5701-110.28c-27.39-21.193-54.287-14.799-77.494-15.855z" stroke="#464646" stroke-width="1.5543" fill="#e5e5e5" />
              <path id="path10809" d="m-5080.3 4005 3.0002 110.9c15.216 7.2082 50.831 15.663 56.933 12.397l-3.4413-114.38c-22.489-9.3323-47.312-12.943-56.492-8.9198z" stroke="#464646" stroke-width="1.0347" fill="#e5e5e5" />
              <path id="path10811" d="m-5140.9 3992.6 4.0104 106.86c14.964 8.976 54.114 18.188 60.216 14.923l-3.9464-109.58c-25.638-9.3045-55.333-16.867-60.28-12.203z" stroke="#464646" stroke-width="1.0347" fill="#e5e5e5" />
              <path id="path10813" d="m-5201.5 3979.3 7.7984 101.05c10.418 8.2184 50.073 21.219 56.175 17.953l-3.6938-105.79c-23.653-7.1398-38.673-14.079-60.28-13.213z" stroke="#464646" stroke-width="1.0347" fill="#e5e5e5" />
              <path id="path10815" d="m-4864.2 3990.2c-177.72-2.3483-270.53-59.076-347.58-28.504 1.7351 96.442 279.25 89.637 348.68 80.306 94.525 3.8198 362.71-16.34 361.4-101.12-79.409-26.78-257.12 40.202-362.51 49.317z" fill-rule="evenodd" fill="#d8c203" />
              <ellipse id="ellipse10817" opacity="0.443"  rx="7.25" ry="8.8137" transform="matrix(0 .51234 -1.6017 0 -1067 6636.5)" cy="2392.6" cx="-4872.8" fill="#fff" />
              <ellipse id="ellipse10819" opacity="0.443"  rx="7.25" ry="15.314" transform="translate(0 1734.3)" cy="2361.1" cx="-4785.2" fill="#fff" />
              <ellipse id="ellipse10821" opacity=".275"  rx="7.25" transform="matrix(.33794 .94117 -.94117 .33794 0 1734.3)" ry="15.314" cy="5173.1" cx="519.47" fill="#fff" />
              <path id="path10823" d="m-4862 4344.5c-175.39 33.458-362.31-71.19-346.55-383.79 4.8145 304.98 283.68 417.22 346.91 305.55 81.634 101.52 327.78 23.925 359.83-319.56 0.8631 336.79-179.57 433.52-360.18 397.8z" fill-rule="evenodd" fill="#cdb803" />
              <path id="path10825" d="m-5150.6 3954.8c9.2979 26.723 275.87 60.608 274.36 42.426-75.066 20.608-175.81 43.616-274.36-42.426z" fill-rule="evenodd" fill-opacity=".31707" fill="#fff" />
              <path id="path10827" d="m-4944.1 4046.7 86.806-4.7033c0.01 11.257-54.712 20.559-86.806 4.7033z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path10829" d="m-4855 4042.5 87.681-2.8283c-1.3182 10.238-58.73 15.215-87.681 2.8283z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path10831" d="m-4769.7 4039.9 84.809-8.9485c-10.862 10.38-51.725 16.639-84.809 8.9485z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path10833" d="m-5022.6 4044.6c25.733 1.7224 49.996 3.4632 75.407 2.2966-0.3499 10.263-47.726 11.033-75.407-2.2966z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path10835" d="m-5079.7 4039.3c23.435 2.9598 32.318 4.5239 56.138 6.0091-10.249 4.2523-41.362-0.8115-56.138-6.0091z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path10837" d="m-5139.5 4027c25.209 5.4486 34.464 9.6409 59.774 12.184-12.446 1.9708-39.101-2.2169-59.774-12.184z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path10839" d="m-5199.9 3992.2c17.394 19.585 36.699 26.043 59.591 34.115-12.515 2.9774-47.084-11.169-59.591-34.115z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path10841" d="m-4684.1 4031c19.583-2.9082 37.18-5.8765 51.273-8.951-7.6667 4.6477-29.182 11.091-51.273 8.951z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path10843" d="m-4631 4021.4c19.546-4.0879 37.482-9.3564 55.886-15.239-6.5633 7.691-33.797 15.889-55.886 15.239z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path10845" d="m-4573.9 4005.6c22.884-8.587 44.816-18.049 59.484-34.195-3.4946 10.806-33.848 38.506-59.484 34.195z" fill-opacity=".085366" fill-rule="evenodd" />
              <ellipse id="ellipse10847" opacity="0.443"  rx="7.25" transform="matrix(.41379 0 0 .41379 -2722.4 3094.4)" ry="15.314" cy="2361.1" cx="-4785.2" fill="#fff" />
              <ellipse id="ellipse10849" opacity="0.443"  rx="7.25" ry="15.314" transform="matrix(.41379 0 0 .41379 -2625.9 3214.9)" cy="2361.1" cx="-4785.2" fill="#fff" />
              <ellipse id="ellipse10851" opacity="0.443"  rx="7.25" transform="matrix(.41379 0 0 .41379 -3049.9 3230.9)" ry="15.314" cy="2361.1" cx="-4785.2" fill="#fff" />
              <ellipse id="ellipse10853" opacity="0.443"  rx="7.25" ry="15.314" transform="matrix(.41379 0 0 1.5912 -3166.2 305.87)" cy="2361.1" cx="-4785.2" fill="#fff" />
              <path id="path10855" d="m-4777.2 4306.7c26.726 2.3919 40.559-0.2699 80.222-15.166-13.427 4.251-71.64 10.555-80.222 15.166z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path10857" d="m-4696.7 4292.3c14.274-2.7498 35.212-22.054 48.361-29.546-10.855-5.597-44.646 22.42-48.361 29.546z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path10859" d="m-4647.2 4262.5c22.934-12.296 52.256-52.106 54.902-56.063-8.8719 1.2626-50.938 53.495-54.902 56.063z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path10861" d="m-4591.1 4204c13.92-10.174 13.081-17.458 20.608-27.955-5.7239 1.4019-16.198 23.318-20.608 27.955z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path10863" d="m-5072.7 4257.2c10.085 7.8935 30.23 27.708 54.116 32.751-12.547-9.1711-32.566-30.888-54.116-32.751z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path10865" d="m-5132.7 4199.1c15.529 12.984 40.054 49.912 58.698 58.34-1.2967-9.6711-51.398-59.477-58.698-58.34z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path10867" d="m-4860.5 4266.8c14.223 20.732 48.953 40.311 80.282 40.393-8.2183-0.2848-74.407-36.713-80.282-40.393z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path10869" d="m-5020.5 4196.6c19.517 8.1575 38.018 18.081 72.241 14.959-8.7833-10.421-37.541-18.603-72.241-14.959z" fill-opacity=".085366" fill-rule="evenodd" />
              <path id="path10871" d="m-5074.7 4186.9c20.943 12.829 19.876 15.685 52.524 8.5733-2.6619-1.7961-38.218-13.093-52.524-8.5733z" fill-rule="evenodd" fill-opacity=".085366" />
              <path id="path10873" d="m-5133.5 4169.5c18.922 8.0538 22.613 14.939 56.9 11.698-2.6619-1.7961-42.593-16.218-56.9-11.698z" fill-opacity=".085366" fill-rule="evenodd" />
          </g>
          <use id="use9169" xlinkHref="#g9158" transform="translate(-30.81 37.249)" height="100%" width="100%" y="0" x="0" />
          <path id="path10899" d="m-458.6 323.77c-37.685 42.689-28.788 83.515-0.6608 123.57-46.638-43.458-61.51-85.379 0.6608-123.57z" fill-rule="evenodd" fill-opacity=".13265" fill="#fff" />
          <path id="path10901" d="m-406.84 311.87c0.9345 0 14.252 8.1772 14.252 8.1772-8.9769-12.008 0.2491-18.475 7.9435-28.27-7.3984 4.5475-14.797 7.7878-22.195 20.092z" fill-opacity=".18878" fill-rule="evenodd" fill="#fff" />
          <path id="path10903" d="m-456.6 314.68 10.28-3.5045c-5.8507-11.8-20.459-16.863-34.11-22.662 10.947 8.3655 26.366 17.776 23.831 26.167z" fill-opacity=".18878" fill-rule="evenodd" fill="#fff" />
          <path id="path10905" d="m-497.06 351.68c-13.984-1.2349-22.597 2.9011-32.208 6.039 9.2022 0.5443 18.404-2.8408 27.607 3.1633z" fill-rule="evenodd" fill-opacity=".27551" fill="#fff" />
          <path id="path10907" d="m-484.41 332.12c1.9517-7.3231-8.8008-13.491-14.378-20.13 7.3809 2.0066 14.762 2.3807 22.143 10.928z" fill-rule="evenodd" fill-opacity=".27551" fill="#fff" />
          <path id="path10909" d="m-501.95 402.86 4.6011 11.79c-11.146-4.4564-21.582 11.881-23.868 18.98 2.7871-8.5017-3.9499-12.406 19.267-30.77z" fill-rule="evenodd" fill-opacity=".27551" fill="#fff" />
          <path id="path10911" d="m-485.56 433.06 7.4768 7.0454c-10.607 3.1076-10.148 14.835-13.66 26.456 0.736-13.217-2.843-21.666 6.1827-33.502z" fill-rule="evenodd" fill-opacity=".27551" fill="#fff" />
          <path id="path10913" d="m-355 396.54-5.1763 15.816c11.376 3.998 18.365-10.564 29.907-21.855-9.7774 2.7033-14.954 14.353-24.731 6.039z" fill-rule="evenodd" fill-opacity=".27551" fill="#fff" />
          <path id="path10915" d="m-375.08 438.96c1.7254-0.8627 7.4768-11.215 7.4768-11.215-1.9297 13.566 10.231 12.57 19.123 14.954-10.928 0.7441-15.673 0.8323-26.6-3.7384z" fill-rule="evenodd" fill-opacity=".27551" fill="#fff" />
          <path id="path10917" d="m-357.02 361.45c2.5881 2.8757 3.7384 10.352 3.7384 10.352l14.954-16.967z" fill-opacity=".27551" fill-rule="evenodd" fill="#fff" />
          <path id="path10919" d="m-372.26 332.12c-0.6277-9.1837 12.328-23.65 13.228-20.274-0.4274-2.1383-8.2986 12.412-6.6141 30.051 0.4322 4.5263-6.6141-9.7774-6.6141-9.7774z" fill-rule="evenodd" fill-opacity=".27551" fill="#fff" />
          <path id="path10921" d="m-426.03 308.54c4.5954-0.0006 8.7219-14.312 8.052-14.378-0.1034 4.8887 1.6218 9.7774-0.8628 14.666z" fill-rule="evenodd" fill-opacity=".27551" fill="#fff" />
          <path id="path10923" d="m-443.72 456.93c-2.403 10.763-2.7532 21.937-3.307 33.07 1.3014-13.412 4.1834-26.744 11.79-31.633z" fill-rule="evenodd" fill-opacity=".27551" fill="#fff" />
          <path id="path10925" d="m-399.58 454.05 15.816 20.993-10.352-25.019z" fill-opacity=".27551" fill-rule="evenodd" fill="#fff" />
          <use id="use9167" xlinkHref="#g9158" transform="translate(35.482 -20.203)" height="100%" width="100%" y="0" x="0" />
          <path id="path10929" d="m-452.78 318.17c13.372-4.1698 38.103-1.7255 45.58 1.0064-13.228-10.065-39.397-5.3199-45.58-1.0064z" fill-rule="evenodd" fill-opacity=".27551" fill="#fff" />
          <g id="g9158">
              <path id="path10727" opacity=".38496" d="m-460.68 336.5c0-1.0645 0.8629-1.9275 1.9275-1.9275 1.0645 0 1.9275 0.863 1.9275 1.9275 0 1.0646-0.863 1.9275-1.9275 1.9275-1.0646 0-1.9275-0.8629-1.9275-1.9275z" fill="#422323" />
              <use id="use9146" xlinkHref="#path10727" transform="matrix(.80347 0 0 .80347 -85.612 64.238)" height="100%" width="100%" y="0" x="0" />
              <use id="use9148" xlinkHref="#path10727" transform="matrix(1.1965 0 0 1.1965 95.082 -62.596)" height="100%" width="100%" y="0" x="0" />
              <use id="use9150" xlinkHref="#path10727" transform="translate(10.607 -.88388)" height="100%" width="100%" y="0" x="0" />
              <use id="use9152" xlinkHref="#path10727" transform="matrix(1.1638 0 0 1.1638 84.033 -46.839)" height="100%" width="100%" y="0" x="0" />
              <use id="use9154" xlinkHref="#path10727" transform="matrix(.60694 0 0 .60694 -167.44 136.05)" height="100%" width="100%" y="0" x="0" />
              <use id="use9156" xlinkHref="#path10727" transform="matrix(.73796 0 0 .73796 -120.97 93.984)" height="100%" width="100%" y="0" x="0" />
          </g>
          
          <use id="use9280" xlinkHref="#g10741" transform="translate(59.094 -.50508)" height="100%" width="100%" y="0" x="0" />
      </g>
      
  </svg>
    )
  }
}
export default SvgGarden